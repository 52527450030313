import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { useNavigate, useParams, Outlet } from "react-router-dom";
import FusePageSimple from '@fuse/core/FusePageSimple';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import LumberjackContext from './context';
import ReferralsPanel from './panels/referrals_list';
import ResidentPanel from './panels/resident_profile';
import UserPanel from './panels/user_profile';
import allData from './data';
import makeFootnoteWrapper from './footnote';
import {getRequests, updateRequest} from './db';
// import Header from './header';
import SummaryToolbar from './pages/clinical/toolbar';
import { current } from '@reduxjs/toolkit';



const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-wrapper': {
    backgroundColor: '#ffffff' // #fafafa  #d5eef4 #ffffff
  },
  '& .FusePageSimple-content': {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 100%',
    height: '100%',
    maxWidth: '100%',
    backgroundColor: '#d5eef4' // #fafafa  #d5eef4
  },
}));

const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: 400,
    maxWidth: '100%',
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      position: 'relative',
    },
  },
}));


function Lumberjack(props) {
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const [showReferralsPanel, setShowReferralsPanel] = useState(true);
  const [showResidentPanel, setShowResidentPanel] = useState(false);
  const [showUserPanel, setShowUserPanel] = useState(false);  
  const [requests, setRequests] = useState();
  const [request, setRequest] = useState();
  const [referrals, setReferrals] = useState([]);
  const [referral, setReferral] = useState();
  const [footnotes, setFootnotes] = useState([]);
  const [footnotePage, setFootnotePage] = useState();
  const [requestData, setRequestData] = useState(); // allData['Donald Duck']
  const routeParams = useParams();
  const navigate = useNavigate();

  // load requests if they don't exist
  useEffect(() => {
    if(requests == null){
      getRequests()
        .then(data => {
          setRequests(data);
        })
    }
  }, [requests, referrals])

  
  function SyncRequest() {
    if(requests == null ) return;
    let currentRequest = null;
    let redirect = false;

    if(routeParams.id == null){                                     // assign default
        currentRequest = requests[requests.length - 1];
    }
    else {                                                          // use query param
      let match = requests.filter(r => r.id == routeParams.id);
      if(match.length > 0)
        currentRequest = match[0];
    }

    if(currentRequest == null) return;

    if(request == null) {
      setRequest(currentRequest);                                   // set "current" request
      SyncRequestData(currentRequest);
      RedirectRequest(currentRequest);
    } 
    else {
      if(request.id == currentRequest.id) return;
      setRequest(currentRequest);                                   // set "current" request
      SyncRequestData(currentRequest);
    }
  }

  const RedirectRequest = (currentRequest) => {
    currentRequest = (currentRequest == null)? request : currentRequest;
    if(currentRequest == null) return;

    let pathname = window.location.pathname.toLowerCase();
    if(pathname.indexOf('clinical') >= 0){     
      navigate(`/Lumberjack/Clinical/${currentRequest.id}`);
    }
    else if( pathname.indexOf('financial') >= 0){        
      navigate(`/Lumberjack/Financial/${currentRequest.id}`);  
    }
  }

  const SyncRequestData = (currentRequest) => {
    currentRequest = (currentRequest == null)? request : currentRequest;
    if(currentRequest == null) return;

    let data = allData[currentRequest.PatientName];
    setRequestData(data);
  }

  SyncRequest();
  

  const deleteRequest = (request) => {

    if(request == null) {
      alert('Please select a referral.');
      return;
    }
    
    request.Show = false;
    updateRequest(request.id, {Show: false});

    //let requestsCopy = request.filter(f => f.id != request.id);
    //setRequests(null);
    for(var i=0;i<requests.length;i++){
      if(requests[i].id != request.id){
        setRequest(requests[i]);
        SyncRequestData(requests[i]);
        RedirectRequest(requests[i]);        
        return;
      }
    }    
  }

  const utils = ({
    // panels 
    showReferralsPanel, setShowReferralsPanel, showResidentPanel, setShowResidentPanel, showUserPanel, setShowUserPanel, 
    // data
    data: requestData, referral, setReferral, footnotes, setFootnotes, footnotePage, requests, setRequests, request, setRequest,
    // tools
    makeFootnote: makeFootnoteWrapper(setFootnotePage), deleteRequest
    
  })

  return(
    <LumberjackContext.Provider value={utils}>
      <Root
      scroll="content"
      content={<Outlet />}
      header={<SummaryToolbar />}
      leftSidebarContent={<ReferralsPanel />}
      leftSidebarOpen={showReferralsPanel}
      leftSidebarOnClose={() => {
        showReferralsPanel(false);
      }}
      leftSidebarWidth={350}

      rightSidebarContent={<ResidentPanel />}
      rightSidebarOpen={showResidentPanel}
      rightSidebarOnClose={() => {
        setShowResidentPanel(false);
      }}
      rightSidebarWidth={400}
    />
    <StyledSwipeableDrawer
          className="h-full absolute z-9999"
          variant="temporary"
          anchor="left"
          open={showUserPanel}
          onOpen={(ev) => {}}
          onClose={() => setShowUserPanel(false)}
          classes={{
            paper: 'absolute left-0',
          }}
          style={{ position: 'absolute' }}
          ModalProps={{
            keepMounted: false,
            disablePortal: true,
            BackdropProps: {
              classes: {
                root: 'absolute',
              },
            },
          }}
        >
          <UserPanel />
    </StyledSwipeableDrawer>
  </LumberjackContext.Provider>
  )
}

function getRequest(file){
  if(file == null) return;
  let name = file.name;
  if(name.indexOf('Michael Jackson') >= 0){
      return {id: '44300546-bd14-481a-9d64-b0682e0ebd9d', name: 'Michael Jackson'}
  }
  else if(name.indexOf('Lois Lane') >= 0){
      return {id: '43300546-bd14-481a-9d64-b0682e0ebd9d'}
  }
  else if(name.indexOf('Donald Duck') >= 0){
      return {id: '42400546-bd14-481a-9d64-b0682e0ebd9d'}
  }
  else if(name.indexOf('Tiny Tim') >= 0){
      return {id: '41400546-bd14-481a-9d64-b0682e0ebd9d'}
  }
}

export default Lumberjack;

