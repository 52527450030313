import React, {lazy} from 'react';
import Module from './module';
import CardImport from '../import-card/Layout'
import MicrosoftViewer from './office/MicrosoftViewer';
import GoogleViewer from './office/GoogleViewer';

const Routes = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'tests',
      element: <Module />
    },{
      path: 'import-card',
      element: <CardImport />
    },{
      path: 'office-test',
      element: <GoogleViewer />
    }
  ]
}

export default Routes;