import React, {useState, useContext} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import DataModelContext from './context';
import {test, data as rows} from './tests';

const columns = [
  { id: 'name', label: 'TEST', minWidth: 10 },
  { id: 'status', label: 'STATUS', minWidth: 50},
  { id: 'description', label: 'DESCRIPTION', minWidth: 100 },  
];


export default function DataModels() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [selected, setSelected] = useState([]); // https://react-material.fusetheme.com/documentation/material-ui-components/table

  const { currentModel, setCurrentModel } = useContext(DataModelContext);
  const [ testResults, setTestRests ] = useState('Test results will display here.');
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  
  const onRowClick = (event, name) => {

    const idx = rows.findIndex(item => item.name == name);
    console.log(`Index: ${idx}, name: ${name}`);
    if(idx == -1) return;
    
    let row = rows[idx];

    if(row?.status == 'DISABLED') {
      setTestRests(''); 
      return
    };

    test(row.script)
      .then(result => {
        row.status = result.status;
        setTestRests(result.message); 
      })
      .catch(result => {
        row.status = result.status;
        setTestRests(result.message); 
      })         

    setSelected(name)
  }


  return (
    <Paper className="m-10 p-20 " sx={{ height: '98%', width: '100%' }}>
      
      <TableContainer  sx={{  width: '100%'  }}>
      
        <Table stickyHeader sx={{ maxHeight: '50%', width: '100%'  }} aria-label="sticky table" size="small">
        
          <TableHead >
            <TableRow >
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, backgroundColor: 'transparent',  color: 'primary.main', fontWeight: 700}}
                  sx={{borderBottom: .1, borderColor: '#496e7d'}}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          
          <TableBody className="m-10" sx={{ maxHeight: '50%', width: '100%'  }}>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {

                const isItemSelected = isSelected(row.name);

                return (
                  <TableRow 
                    hover 
                    role="checkbox" 
                    tabIndex={-1} 
                    key={row.script}
                    onClick={(event) => onRowClick(event, row.name)}
                    selected={isItemSelected}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
           
      <br/>
      
      <textarea id="testresults" style={{height: '40%',  width: '100%', border: 'solid 1px #ccc', boxShadow: 'inner 0 0 4px rgba(0, 0, 0, 0.2)', borderRadius: '6px', padding: 10}} value={testResults}>
        
      </textarea>
      
    </Paper>
  );
}