import { useContext } from 'react';
import AppContext from './AppContext';
import { AuthContext } from './AuthContext';
import { matchRoutes } from 'react-router-dom';
import withRouter from './utils/withRouter';
import FuseUtils from '@fuse/utils';
import history from '@history';

//, '/email-confirmed'
const ignoredPaths = ['/', '/sign-in', '/sign-out', '/_test', '/register', '/register/test', '/sign-up', '/logout', '/404', '/reset-password', '/email-confirmed', '/forgot-password', '/manage-account'];

const SecureRouter = ({children, location}) => {
  const { currentUser } = useContext(AuthContext);
  const { routes } = useContext(AppContext);  
  const { pathname } = location;  

  // reroute to default homepage:
  if(currentUser?.homepage != null && currentUser?.homepage != ''){
    if(pathname.toLowerCase() == '/free'){
      setTimeout(() => history.push(currentUser?.homepage), 50); 
    }
  }
  const matchedRoutes = matchRoutes(routes, pathname);

  const matched = matchedRoutes ? matchedRoutes[0] : false;     

  let ignoreAuth = ignoredPaths.includes(pathname);  

  if(ignoreAuth){
    return (<>{children}</>)
  }
  else {
    if(currentUser == null){
      console.log(`Unauthenticated User!`);      
      setTimeout(() => history.push('/sign-in'), 500); 
      return (null)
    }

    const hasPermission = FuseUtils.hasPermission(matched.route.auth, currentUser?.orgs); 
    if(!hasPermission){
      console.log(`Unauthorized`);
      return (null)
    } 
    else{
      return (<>{children}</>)
    }
  }

}

export default withRouter(SecureRouter);