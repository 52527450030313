import FuseUtils from '@fuse/utils';
import FuseLoading from '@fuse/core/FuseLoading';
import { Navigate } from 'react-router-dom';
import settingsConfig from 'app/configs/settingsConfig';
import SignInConfig from '../main/authentication/sign-in/SignInConfig';
import SignUpConfig from '../main/authentication/sign-up/SignUpConfig';
import SignOutRoutes from '../main/authentication/sign-out/SignOutRoutes';
import ConfirmationRequiredConfig from '../main/authentication/confirmation-required/confirmationRequiredConfig';
import ConfirmedEmailRoutes from '../main/authentication/confirmed-email/ConfirmedEmailRoutes';
import ResetPasswordPagesConfig from '../main/authentication/reset-password/routes';
import ForgotPasswordRoutes from '../main/authentication/forgot-password/ForgotPasswordRoutes';
import AuthCallbackHandler from '../main/authentication/manage-account/routes'
//import AuthenticationRoutes from '../main/authentication/authenticationRoutes';
import Error404Page from '../main/404/Error404Page';
// import ReferralRoutes from '../modules/referrals/routes';
import IntegrationRoutes from '../modules/integration/routes';
import SupportRoutes from '../modules/support/routes';
import DataModelRoutes from '../modules/integration/DataModels/routes';
import SystemTestsRoutes from '../modules/system/test/routes';
import PrompRoutes from '../modules/prompts/routes';
import AutomationRoutes from '../modules/automation/routes';
import LumberjackRoutes from '../modules/lumberjack/routes';
import RegulationChat from '../modules/chat_regulation/routes';
// import PolicyChat from '../modules/chat_policy/routes';
import ClientOldRoutes from '../modules/clients/routes';
import IamRoutes from '../modules/iam/routes';
import ClientRoutes from '../clients';
import RegistrationRoutes from '../registration/RegistrationRoutes';
import PresentationRoutes from '../modules/demo/routes';

const routeConfigs = [
  PresentationRoutes,
  RegistrationRoutes,
  ClientRoutes,
  AuthCallbackHandler,
  ForgotPasswordRoutes,
  ResetPasswordPagesConfig,
  ConfirmedEmailRoutes,
  ConfirmationRequiredConfig,
  SignInConfig,
  SignUpConfig,
  SignOutRoutes,
  IntegrationRoutes, SupportRoutes, DataModelRoutes, SystemTestsRoutes, PrompRoutes, AutomationRoutes, LumberjackRoutes, RegulationChat, ClientOldRoutes, IamRoutes];

// auth: settingsConfig.defaultAuth,

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, settingsConfig.defaultAuth),
  {
    path: '/',
    element: <Navigate to="Free" />
  },
  {
    path: 'loading',
    element: <FuseLoading />,
  },
  {
    path: '404',
    element: <Error404Page />,
  },
  {
    path: '*',
    element: <Navigate to="404" />,
  }
];

/*
{
    path: '*',
    element: <Navigate to="404" />,
  },
  */

export default routes;
