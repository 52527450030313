
let data = {
  script: 'AuthAdmin',
  name: "Authenticate as Admin",
  description: 'Use Admin SDK ot connect to Firestore.',
  enabled: true
}

function test(RESULT_TYPE) {

  return new Promise((resolve, reject) => {
    resolve({
      status: RESULT_TYPE.PASSED, 
      message: 'It worked!'
    })
  }) // Promise
  
}

export default {test: test, data};