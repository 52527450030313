import React, {useState, useContext} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import PromptContext from './context';
import Slide from '@mui/material/Slide';

import ReferralSelector from './TestConfigurations/ReferralSelector';


const columns = [
  { id: 'Name', label: 'PROMPT', minWidth: 30 },
  { id: 'Enabled', label: 'ENABLED', minWidth: 20, format: (value) => value.toString(), align: 'center' },
  { id: 'Description', label: 'DESCRIPTION', minWidth: 100 },  
];

export default function PromptLandingPage() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [selected, setSelected] = useState([]); // https://react-material.fusetheme.com/documentation/material-ui-components/table

  // const { mainSidebarOpen, setMainSidebarOpen, setContactSidebarOpen } = useContext(ReferralContext);
  
  const containerRef = React.useRef(null);
  const { prompts, currentPrompt, setCurrentPrompt, showTestShell, setShowTestShell } = useContext(PromptContext);
  const [ testResults, setTestRests ] = useState('Test results will display here.');
  const [ referralToTest, setReferralToTest ] = useState();
  
  const setTestReferral = (referral) => {
    setReferralToTest(referral);
  }

  

  const toggleShowTestShell = () => {
    setShowTestShell((prev) => !prev);
  };


  let TestShell =  
   (
    <Paper sx={{ m: 1, width: '97%', height: '93%', position: 'absolute', left: '15px', top: '20px', zIndex: 100, overflowX: 'hidden', overflowY: 'scroll' }} elevation={4}>
      <ReferralSelector referralToTest={referralToTest} setReferralToTest={setTestReferral} setShowTestShell={setShowTestShell} currentPrompt={currentPrompt}/>
    </Paper>
  );

  if(prompts == null) {
    return (<div>Loading</div>)
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  

  const isSelected = (name) => selected.indexOf(name) !== -1;
  
  const onRowClick = (event, name) => {

    const idx = prompts.findIndex(prompt => prompt.Name == name);
       
    console.log(`Index: ${idx}, name: ${name}`);
    if(idx == -1) return;
    
    let prompt = prompts[idx];
    

    /*

    if(prompt?.status == 'DISABLED') {
      setTestRests(''); 
      return
    };

    test(prompt.script)
      .then(result => {
        prompt.status = result.status;
        setTestRests(result.message); 
      })
      .catch(result => {
        prompt.status = result.status;
        setTestRests(result.message); 
      })         
    */

    setCurrentPrompt(prompt);
    setSelected(name)
  }

  // Transitions
  // https://react-material.fusetheme.com/documentation/material-ui-components/transitions

  return (
    <Paper className="m-10 p-20 " sx={{ height: '98%', width: '100%', overflow: 'hidden' }}>
      <TableContainer  sx={{  width: '100%'  }} ref={containerRef}>
        <Table stickyHeader sx={{ maxHeight: '50%', width: '100%'  }} aria-label="sticky table" size="small">
          <TableHead >
            <TableRow >
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column?.align}
                  style={{ minWidth: column.minWidth, backgroundColor: 'transparent',  color: 'primary.main', fontWeight: 700}}
                  sx={{borderBottom: .1, borderColor: '#496e7d'}}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          
          <TableBody className="m-10" sx={{ maxHeight: '50%', width: '100%'  }}>
            {prompts
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((prompt) => {

                const isItemSelected = isSelected(prompt.Name);
                {/* key={row.script} */}
                return (
                  <TableRow 
                    hover 
                    role="checkbox" 
                    tabIndex={-1} 
                    key={prompt?.id}
                    onClick={(event) => onRowClick(event, prompt.Name)}
                    selected={isItemSelected}
                  >
                    {columns.map((column) => {
                      const value = prompt[column.id];                      
                      return (
                        <TableCell key={column.id} align={column?.align}>
                          {(column?.format == null)
                            ? value
                            : column.format(value) }
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        </TableContainer>
      
        <Slide direction="up" in={showTestShell} container={containerRef.current}>
          {TestShell}
        </Slide>

         {/* 
        <br/>
        <TablePagination
          component="div"
          count={prompts.length}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      
      <br/>
      
      <textarea id="testresults" style={{height: '40%',  width: '100%', border: 'solid 1px #ccc', boxShadow: 'inner 0 0 4px rgba(0, 0, 0, 0.2)', borderRadius: '6px', padding: 10}} value={testResults}>
        
      </textarea>
      */}
    </Paper>
  );
}