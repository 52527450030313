import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  IconButton,
  Modal,
  Typography,
  TextField,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LockIcon from "@mui/icons-material/Lock";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import MuiPhoneNumber from "mui-phone-number";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CheckCircle } from "@mui/icons-material";
import {getAuth,linkWithCredential,PhoneAuthProvider,unlink} from "firebase/auth";
import useAuthContext from "src/app/AuthContext";
import { doc, updateDoc } from "firebase/firestore";
import { auth, firestore as db } from "src/app/auth/Firebase";
import { CONTAINER_USERS } from "src/app/auth/services/firebase/firestore";

const schema = yup.object().shape({
  phone: yup
    .string()
    .required("Phone number is required")
    .min(10, "Phone number should be of minimum 10 digits"),
});
  
const VerifyPhoneModal = ({ isOpen, onRequestClose,onSuccessHandler,defaultPhoneNumber }) => {
  const [recaptchaSignature, setRecaptchaSignature] = useState(null);
  const [verificationId, setVerificationId] = useState(null);
  const {signInWithPhoneNumber,recaptchaVerifier} = useAuthContext();
  const [isPhoneSubmitted, setIsPhoneSubmitted] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: "onChange",
    defaultValues: defaultPhoneNumber || '',
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [otpVerified, setOtpVerified] = useState(false);
  const otpRefs = useRef([]);

  const handlePhoneSubmit = async ({ phone }) => {
    const formattedPhone = phone.replace(/[^\d+]/g, "");
    if (formattedPhone.length < 10) {
      setError("phone", {
        type: "manual",
        message: "Please enter a valid phone number.",
      });
      return;
    }
    setIsPhoneSubmitted(true);
    sendOtp(formattedPhone.replace("+1","+1 "));
    let user = auth.currentUser;
    await updateDoc(
      doc( db,CONTAINER_USERS, user.uid), { phoneNumber: formattedPhone });
  };

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;
    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    // Move focus to next input
    if (element.value !== "" && index < otp.length - 1) {
      otpRefs.current[index + 1].focus();
    }

    // Check if all OTP inputs are filled
    if (newOtp.every((value) => value !== "")) {
      setOtpVerified(false);
    }
  };

  useEffect(() => {
    const enteredOtp = otp.join("");
    if (enteredOtp.length == 6) {
      setLoading(true); // Start loader
      verifyOtp(enteredOtp);
    } 
  }, [otp]);

  const handleModalClose = () => {
    setIsPhoneSubmitted(false);
    setOtpVerified(false);
    setValue("phone", defaultPhoneNumber);
    setOtp(new Array(6).fill(""));
    onRequestClose();
  };


  useEffect(()=>{
if(defaultPhoneNumber?.length>=10){
  setValue("phone",defaultPhoneNumber)
}
  },[defaultPhoneNumber])
  const renderOtpInputs = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginBottom: "12px",
      }}
    >
      {otp.map((data, index) => (
        <TextField
          key={index}
          value={data}
          onChange={(e) => handleChange(e.target, index)}
          onFocus={(e) => e.target.select()}
          variant="outlined"
          inputRef={(el) => (otpRefs.current[index] = el)}
          inputProps={{
            maxLength: 1,
            style: { textAlign: "center", fontSize: "20px" },
          }}
          sx={{ width: "5rem", margin: "0 0.5rem", fontSize: 20 }}
        />
      ))}
    </div>
  );

  useEffect(() => {
    if (!window.recaptchaVerifier) {
    const signature = recaptchaVerifier();
     
    setRecaptchaSignature(signature);

    return () => {
      signature.clear();
      setRecaptchaSignature(null);
    };
  }
  }, []);

  function sendOtp(phone) {
console.log(phone)
    if (phone == "") {
      alert("phone number is required");
      return;
    }

    signInWithPhoneNumber(phone, recaptchaSignature)
      .then((result) => {
        console.log(result);
        console.log("OTP sent");
        setVerificationId(result.verificationId);
      })
      .catch((ex) => {
        console.log(ex.message);
      });
  }
  function verifyOtp(enteredOtp) {
    let otp = enteredOtp;
    if (otp == "") {
      alert("otp is required");
      return;
    }
    const credential = PhoneAuthProvider.credential(verificationId, otp);
 const linkPhoneNumber = () => {
      linkWithCredential(auth.currentUser, credential)
        .then(async (usercred) => {
          console.log("Phone number linked successfully!");
          setTimeout(() => {
            setLoading(false);
            setOtpVerified(true);
          }, 1000);
          let verify = auth.currentUser;
          await updateDoc(doc(db, CONTAINER_USERS, user.uid),{ phoneVerified: true });
          onSuccessHandler(); //this will trigger the parent component's function
        })
        .catch((error) => {
          console.log("Error during linkWithCredential:", error);
          console.log("Error linking phone number: " + error.message);
        });
    };

    //Unlink is mandatory if we want to change/link the phone number
    unlink(auth.currentUser, "phone")
      .then(() => {
        linkPhoneNumber();
      })
      .catch((error) => {
        if (error.code === "auth/no-such-provider") {
          linkPhoneNumber();
        } else {
          console.error("Error during unlink:", error); 
        }
      }); 
  }

  useEffect(() => {
    console.log(auth.currentUser);
  }, [auth]);

  return (
    <Modal
      open={isOpen}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 1,
          width: "100%",
          maxWidth: 580,
        }}
      >
        <div className="modal-header p-12">
          <div className="flex items-center gap-4">
            <LockIcon />
            <Typography variant="h6" component="h2">
              {isPhoneSubmitted
                ? otpVerified
                  ? "OTP Verified!"
                  : "Enter your verification code"
                : "Keep your account secure"}
            </Typography>
          </div>
          <div className="absolute right-0 top-0 m-4">
            <IconButton aria-label="close" onClick={handleModalClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <hr />

        <div className="modal-body p-12">
          {isPhoneSubmitted ? (
            <>
              {loading ? (
                <div className="text-center py-12 mb-12">
                  <CircularProgress color="success" />
                  <Typography variant="h6" component="h2" className="my-12">
                    Verifying OTP...
                  </Typography>
                </div>
              ) : otpVerified ? (
                <div className="text-center py-12 mb-10">
                  <CheckCircle sx={{ fontSize: 70, color: "#11b823" }} />
                  <Typography
                    variant="h4"
                    component="h2"
                    className="font-semibold"
                  >
                    Success
                  </Typography>
                  <Typography
                    variant="p"
                    component="p"
                    className="text-xl py-10"
                  >
                    Your Phone is now successfully Verified
                  </Typography>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={handleModalClose}
                    className="text-xl p-0 rounded-md"
                    sx={{ background: "#11b823", width: 300, margin: 2 }}
                  >
                    OK
                  </Button>
                </div>
              ) : (
                <>                 
                    <Typography className="text-center py-9 mb-12">
                      We sent a verification code to your phone number.
                    </Typography>
                    {renderOtpInputs()}
                    <Typography
                      className="text-center mt-4"
                      style={{ color: otpVerified ? "green" : "red" }}
                    >
                      {otpVerified ? "OTP verified" : ""}
                    </Typography>           
                </>
              )}
            </>
          ) : (
            <form onSubmit={handleSubmit(handlePhoneSubmit)}>
              <Typography className="text-center py-9 mb-12">
                We will send you a one-time verifying code
              </Typography>
              <div className="input-group flex gap-12 items-center my-12">
                <label htmlFor="phone-number">
                  <PhoneAndroidIcon />
                </label>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <MuiPhoneNumber
                      {...field}
                      defaultCountry="us"
                      onlyCountries={["us"]}
                      disableDropdown
                      variant="outlined"
                      className="ml-10"
                      label="Phone"
                      error={!!errors.phone}
                      helperText={errors?.phone?.message}
                      fullWidth
                    />
                  )}
                />
              </div>
              <div className="button-group text-right">
                <Button
                  type="submit"
                  className="rounded my-5"
                  disabled={!isValid || submitted}
                  variant="contained"
                  color="secondary"
                >
                  Verify mobile number
                </Button>
              </div>
            </form>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default VerifyPhoneModal;
