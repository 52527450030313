import AutoAuth from './tests/AutoAuth.js';
import AuthUser from './tests/AuthUser.js';
import AuthAdmin from './tests/AuthAdmin.js';

let data = [
  AutoAuth.data, 
  AuthUser.data, 
  AuthAdmin.data
]

const TESTS = {
  AutoAuth: AutoAuth.test, 
  AuthUser: AuthUser.test, 
  AuthAdmin: AuthAdmin.test
};

const RESULT_TYPE = {
  FAILED: 'Failed',
  PASSED: 'Passed',
  ERROR: 'ERROR',
  MISSING: 'NOT FOUND'
}

function test(name){

  let result = {status: '', message: ''}  
  try {
    let t = TESTS[name];

    if(t == null || typeof t != 'function')
      result.status = RESULT_TYPE.MISSING;
    else {      
      result = t(RESULT_TYPE)
    }
  } 
  catch(ex){
    result.status = RESULT_TYPE.ERROR;
    result.message = ex.message;
  }

  return result;
}

/*
for(let i =0; i < data.length;i++){
  if(data[i]?.enabled == null || data[i]?.enabled == false){
    data[i].status = 'DISABLED';
  }
}
*/
data.forEach(row => {
  if(row?.enabled == null || row.enabled == false)
    row.status = 'DISABLED'
})

export {test, data};
