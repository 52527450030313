import { ThemeProvider } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Hidden from "@mui/material/Hidden";
import Toolbar from "@mui/material/Toolbar";
import clsx from "clsx";
import { memo, useContext } from "react";
import { useSelector } from "react-redux";
import {
  selectFuseCurrentLayoutConfig,
  selectToolbarTheme,
} from "app/store/fuse/settingsSlice";
import { selectFuseNavbar } from "app/store/fuse/navbarSlice";
import AdjustFontSize from "../../shared-components/AdjustFontSize";
import FullScreenToggle from "../../shared-components/FullScreenToggle";
import LanguageSwitcher from "../../shared-components/LanguageSwitcher";
// import NotificationPanelToggleButton from '../../shared-components/notificationPanel/NotificationPanelToggleButton';
// import NavigationShortcuts from '../../shared-components/NavigationShortcuts';
import NavigationSearch from "../../shared-components/NavigationSearch";
import NavbarToggleButton from "../../shared-components/NavbarToggleButton";
import UserMenu from "../../shared-components/UserMenu";
// import QuickPanelToggleButton from '../../shared-components/quickPanel/QuickPanelToggleButton';
// import ChatPanelToggleButton from '../../shared-components/chatPanel/ChatPanelToggleButton';
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import ClinicalHeader from "src/app/modules/lumberjack/globalHeader";
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery";
import AppContext from "src/app/AppContext";
import { matchRoutes } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

function getProps(pathname, matchedRoutes) {
  for (let i = 0; i < matchedRoutes.length; i++) {
    let match = matchedRoutes[i];

    if (match.pathname == pathname || match.pathname == pathname + "/") {
      if (match?.route?.element?.props?.pageTitle != null)
        return match.route.element.props;
    }
  }
}

function ToolbarLayout1(props) {
  const config = useSelector(selectFuseCurrentLayoutConfig);
  const navbar = useSelector(selectFuseNavbar);
  const toolbarTheme = useSelector(selectToolbarTheme);
  const location = useLocation();
  const routeParams = useParams();
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));
  const { routes } = useContext(AppContext);
  const { pathname } = location;
  const navigate = useNavigate();

  let title = "";
  let description = "";

  // let pathname = location.pathname.toLowerCase();

  const matchedRoutes = matchRoutes(routes, pathname);
  if (matchedRoutes) {
    let props = getProps(pathname, matchedRoutes);

    title = props?.pageTitle;

    if (props?.pageDescriptionMobile == null) {
      description = props?.pageDescription;
    } else {
      description = isMobile
        ? props?.pageDescriptionMobile
        : props?.pageDescription;
    }
  }
  // support navigation
  const handleRedirect = () => {
    navigate("/support");
  };
  /*
    if(pathname.indexOf('/referral') > -1){
      title = 'Referrals';
      description = 'Dashboard';
      if(pathname.indexOf('/referral/new') > -1){
        title = 'New Referral';
        description = 'Upload Request to Initiate';
      }
      else if(pathname.indexOf('/referral/process') > -1){
        title = 'Referral Processing';
        description = "Monitor progress of the AI evaluation below.";
      }
      
      else if(location.pathname.length > 10){
        title = 'Referral';
        description = 'Evaluation Details';
      }
    } // end if referral
    */

  /*
    else if(pathname.indexOf('/regulationchat') > -1){
      title = 'Regulation Chat';
      description = isMobile? "CMS SNF Rules": 'Get answers to CMS Rules of Participation for Skilled Nursing';
    }
  
    else if(pathname.indexOf('/policychat') > -1){
      title = 'Policy Chat';
      description = isMobile? "": "Your Virtual Assistant to Organizational Policies";
    }
      */

  //else if(pathname.indexOf('/lumberjack') > -1){
  //  return (<ClinicalHeader />)
  //}

  return (
    <ThemeProvider theme={toolbarTheme}>
      <AppBar
        id="fuse-toolbar"
        className={clsx("flex relative z-20 shadow-md", props.className)}
        color="default"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? toolbarTheme.palette.background.paper
              : toolbarTheme.palette.background.default,
        }}
        position="static"
      >
        <Toolbar className="p-0 min-h-48 md:min-h-64">
          <div className="flex flex-1 px-16">
            {config.navbar.display && config.navbar.position === "left" && (
              <>
                <Hidden lgDown>
                  {(config.navbar.style === "style-3" ||
                    config.navbar.style === "style-3-dense") && (
                    <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                  )}

                  {config.navbar.style === "style-1" && !navbar.open && (
                    <NavbarToggleButton className="w-40 p-0 mx-0" />
                  )}
                </Hidden>

                <Hidden lgUp>
                  <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
                </Hidden>
              </>
            )}

            {pathname.indexOf("/lumberjack") >= 0 && <ClinicalHeader />}
            {/* SECTION TITLE */}
            {
              pathname.indexOf("/lumberjack") == -1 && (
                <div className="flex flex-col sm:flex-row items-center sm:space-x-8">
                  <div className="pl-14">
                    <div className="flex flex-col ">
                      <Typography
                        component={motion.span}
                        initial={{ x: -20 }}
                        animate={{ x: 0, transition: { delay: 0.2 } }}
                        delay={300}
                        className="text-24 md:text-24 font-extrabold tracking-tight leading-none"
                      >
                        {title}
                      </Typography>
                    </div>
                    <div className="flex flex-col">
                      <Typography
                        component={motion.span}
                        initial={{ y: -20, opacity: 0 }}
                        animate={{
                          y: 0,
                          opacity: 1,
                          transition: { delay: 0.2 },
                        }}
                        delay={500}
                        className="text-14 font-medium "
                        color="text.secondary"
                      >
                        {description}
                      </Typography>
                    </div>
                  </div>
                </div>
              )
              /* 
              <Hidden lgDown>
                <NavigationShortcuts />
              </Hidden>
              */
            }
            {/* END SECTION TITLE */}
          </div>
          <div className="flex items-center px-8 h-full overflow-x-auto">
            <NavigationSearch />
            <LanguageSwitcher />
            <AdjustFontSize />
            <FullScreenToggle />
            <Tooltip title="Support" placement="bottom">
              <IconButton
                onClick={handleRedirect}
                className={clsx("w-40 h-40")}
                size="large"
              >
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
            {/* 
              <ChatPanelToggleButton />
                <QuickPanelToggleButton />
                <NotificationPanelToggleButton />
              */}

            <UserMenu />
          </div>

          {config.navbar.display && config.navbar.position === "right" && (
            <>
              <Hidden lgDown>
                {!navbar.open && (
                  <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                )}
              </Hidden>

              <Hidden lgUp>
                <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
              </Hidden>
            </>
          )}
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default memo(ToolbarLayout1);
