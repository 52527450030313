
import React, {useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Button from '@mui/material/Button';
import {subscribeTest} from '../data';
import humanizeDuration from 'humanize-duration';
import {Timestamp} from 'firebase/firestore';


const STYLE_LABLES = {color: '#a5a1a1', whiteSpace: 'nowrap'};

// Monitor Prompt Run
// -----------------------
// Binds to the 'Prompt Run' db doc matching testId. Automatically reflects DB updates.
//
function MontiorTest(props) {

  const {testId, referral} = props;   // Note: this is written for reuse.  Therefore, only testId is passed in. TODO: if not passed in get location query params (URL)
  const [promptRun, setPromptRun] = useState();
  const [prompt, setPrompt] = useState();
  
  useEffect(() => {    
    if(testId != null ){
      if(promptRun?.id != testId){
        subscribeTest(testId, setPromptRun);
      }
    }
  }, [promptRun, testId])

  
  let startedOn = '';
  let endedOn = '';
  let duration = '';
  let createdOn = '';

  if(promptRun?.StartedOn != null){
    const timestamp = new Timestamp(promptRun.StartedOn.seconds, promptRun.StartedOn.nanoseconds)
      startedOn = timestamp.toDate();   
  }
  if(promptRun?.CompletedOn != null){
    const timestamp = new Timestamp(promptRun.CompletedOn.seconds, promptRun.CompletedOn.nanoseconds)
      endedOn = timestamp.toDate();    
  }

  if(promptRun?.CreatedOn != null){
    const timestamp = new Timestamp(promptRun.CreatedOn.seconds, promptRun.CreatedOn.nanoseconds)
    createdOn = timestamp.toDate();   
  }
  if(startedOn != '' && endedOn != ''){
    let dif = endedOn - startedOn;
    duration = humanizeDuration(dif);
  }

  const colorStatus = (status) => {
    if(status == 'Running')
      return (<span style={{color: 'green'}}>{status}</span>)
    else if(status == 'Failed')
      return (<span style={{color: 'red'}}>{status}</span>)

    return status;
  }

  const colorPageErr = (val) => {
    if(val != 0)
      return (<span style={{color: 'red'}}>{val}</span>)
    return val;
  }
  

  return (
    <div className="ml-18">

      <table style={{width: '100%', textAlign: 'left'}}>    
        <tbody>
           {/* Row 1*/}
          <tr>          
            {/* Col 1*/}
            <td style={{width:'350px', textAlign: 'left', verticalAlign: 'top'}}>
              {/* Table 1*/}
              <table style={{width: '350px', textAlign: 'left'}}>
                <tbody>
                  <tr>
                    <td style={STYLE_LABLES}>Status</td>
                    <td><strong>{colorStatus(promptRun?.Status)}</strong></td>
                  </tr>
                  <tr>
                    <td style={STYLE_LABLES}>Name</td>
                    <td>{promptRun?.Name}</td>
                  </tr>                
                  <tr>
                    <td style={STYLE_LABLES}>Version</td>
                    <td >{promptRun?.Version}</td>
                  </tr>
                  <tr>
                    <td style={STYLE_LABLES}>Model</td>
                    <td >{promptRun?.Settings}</td>
                  </tr>
                  
                </tbody>
              </table>
            </td>
            {/* Col 2*/}
            <td style={{width:'100%', textAlign: 'left', verticalAlign: 'top'}}>
              {/* Table 2*/}
              <table style={{width: '100%', textAlign: 'left'}}>  
                <tbody>
                <tr>
                    <td style={STYLE_LABLES}>Prompt Run</td>
                    <td >{promptRun?.id}</td>
                  </tr>
                  <tr>
                    <td style={STYLE_LABLES}>Created On</td>
                    <td>{createdOn.toString()}</td>
                  </tr>
                  <tr>
                    <td style={STYLE_LABLES}>Started On </td>
                    <td >{startedOn.toString()}</td>
                  </tr>
                  <tr>
                    <td style={STYLE_LABLES}>Ended On</td>
                    <td>{endedOn.toString()}</td>
                  </tr>
                  <tr>
                    <td style={STYLE_LABLES}>Duration</td>
                    <td>{duration}</td>
                  </tr>   
                </tbody>     
              </table>
            </td>
          </tr>
          {/* Row 2*/}
          <tr>          
            {/* Col 1*/}
            <td style={{width:'200px', textAlign: 'left', verticalAlign: 'top'}}>
              
              <table style={{width: '200px', marginTop: '20px', textAlign: 'left'}}>
                <tbody>
                  <tr>
                    <td>{colorStatus(promptRun?.PagesToProcess)}</td>
                    <td style={STYLE_LABLES}>Pages to Process</td>
                    
                  </tr>
                  <tr>
                    <td>{promptRun?.PagesProcessed}</td>
                    <td style={STYLE_LABLES}>Processed Pages</td>                    
                  </tr>   
                  <tr>
                    <td><strong>{promptRun?.PagesToProcess - promptRun?.PagesProcessed}</strong></td>
                    <td style={STYLE_LABLES}>Outstanding</td>                    
                  </tr>                
                </tbody>
              </table>
            </td>
            {/* Col 2*/}
            <td style={{width:'100%', textAlign: 'left', verticalAlign: 'top'}}>
              <table style={{width: '200px', marginTop: '20px', textAlign: 'left'}}>
                <tbody>           
                  <tr>
                    <td>
                      <div>{promptRun?.PagesUnique}</div>
                    </td>
                    <td style={STYLE_LABLES}>
                      <div>Unique Pages</div>
                    </td>                    
                  </tr>
                  <tr>
                    <td>
                      <div>{colorPageErr(promptRun?.PagesFailedToProcess)}</div>
                    </td>
                    <td style={STYLE_LABLES}>
                      <div>Pages Failed Processing</div>
                    </td>                    
                  </tr>              
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      
      
      {(promptRun?.HasErrors != null && promptRun?.HasErrors == true) && 
        <div style={{marginTop: '30px'}}>
          <div>Error Message</div>
          <div style={{marginLeft:'30px', marginTop: '10px'}}>Errors were reported in the database.  
          
          <a href={`https://console.firebase.google.com/project/lumberjack-396203/firestore/data/~2FPrompt%20Runs~2F${promptRun?.id}`} target='_blank'>Please click this link to review</a>
          </div>
        </div>
      }
      {(promptRun?.HasErrors == null || promptRun?.HasErrors == false) && 
        <div style={{marginTop: '30px'}}>          
        {/*<div >No errors have been reported!</div> 
        https://console.firebase.google.com/project/lumberjack-396203/firestore/data/~2FPrompt%20Runs~2FQvJ14oX23ppFYr0cLlMq
        https://console.firebase.google.com/project/lumberjack-396203/firestore/data/~2FPrompt%20Runs~2FQvIjYlSsZ7bmA41OZdZf
        https://console.firebase.google.com/project/lumberjack-396203/firestore/data/~2FPrompt%20Runs~2F2MHnLOnC1IyYLGBBXbSq
        */} 
          <div>You will be notified of an error here, should one occur!  Meanwhile, check the 'Prompt Run' in the database for errors. </div>
          <a href={`https://console.firebase.google.com/project/lumberjack-396203/firestore/data/~2FPrompt%20Runs~2F${promptRun?.id}`} target='_blank'>You can alternatively monitor for them by clicking this link.</a>
        </div>
      }
    </div>
  )
}

export default MontiorTest;