// Note this function is available in the LumberjackContext

const styleFoot1st = {paddingLeft: '7px', fontSize: '12px', textDecoration: 'none', color: '#297d8b', cursor: 'pointer' };
const styleFoot = {paddingLeft: '9px', fontSize: '12px', textDecoration: 'none', color: '#297d8b', cursor: 'pointer' };

function makeFootnoteWrapper(setFootnotePage){

  const makeFootnote = function(links) {

    function callback(link) {
      setFootnotePage(link)
    }

    if(links?.length != null && links.length == 0) return;  
    let result = links.map((link, idx) => {
      let style = (idx == 0)? styleFoot1st : styleFoot;
      // return <a style={style} onClick={callback} href="#"><sup>{link}</sup></a>    
      return <span style={style} onClick={() => callback(link)} ><sup>{link}</sup></span>    
    })
    return <>{result}</>;  
  }

  return makeFootnote

}



export default makeFootnoteWrapper