import i18next from 'i18next';
// import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import mx from './navigation-i18n/mx';
// import tr from './navigation-i18n/tr';

i18next.addResourceBundle('en', 'navigation', en);
// i18next.addResourceBundle('tr', 'navigation', tr);
// i18next.addResourceBundle('ar', 'navigation', ar);
i18next.addResourceBundle('mx', 'navigation', mx);
i18next.addResourceBundle('ru', 'navigation', en);

// icon: 'heroicons-outline:clipboard-check',
// icon: 'heroicons-outline:question-mark-circle',
/*
          {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: 'referral',
            end: true,
          },
          */
         
  // TODO: CLEAN UP
  /*
  {
        id: 'demoPoliciesGroup',
        title: 'Policies',        
        type: 'collapse',
        auth: ['admin', 'demo'],
        children: [          
          {
            id: 'demoBrightsourcePolicy',
            title: 'Corporate Policies',
            type: 'item',
            url: 'brightpolicy',
            auth: ['admin', 'demo'],
            end: true,
          },
          {
            id: 'demoPharmacyRxPolicy',
            title: 'Pharamcy Policies',
            type: 'item',
            url: 'brightpolicy-rx',
            auth: ['admin', 'demo'],
            end: true,
          },
        ]
      },
      */
/* SHOLOM FULL ROUTES

{
    id: 'sholomNav', 
    title: 'Bright Sources',    
    active: 'square',
    type: 'group',
    icon: 'heroicons-outline:bolt',
    checkPermission: true,
    auth: ['admin', 'sholom'],
    children: [
      
      {
        id: 'sholomBrightSource',
        title: 'Sholom Community Alliance',
        type: 'collapse',
        checkPermission: true,
        url: 'Sholom',
        auth: ['admin', 'sholom'],
        children: [

          {
            id: 'sholom-services',
            title: 'Services',
            url: 'Sholom/Services',
            type: 'collapse',
            children: [         
              {
                id: 'sholom-services-AssistedLiving',
                title: 'Assisted Living',
                type: 'item',
                url: 'Sholom/Services/AssistedLiving',
                end: true,
              },   
              {
                id: 'sholom-services-PhysicalTherapyAquatics',
                title: 'Physical Therapy / Aquatics',
                type: 'item',
                url: 'Sholom/TODO',
                end: true,
              },
              {
                id: 'sholom-services-Catering',
                title: 'Catering',
                type: 'item',
                url: 'Sholom/TODO',
                end: true,
              },
              {
                id: 'sholom-services-HUD-SupportedHousing',
                title: 'HUD-Supported Housing',
                type: 'item',
                url: 'Sholom/TODO',
                end: true,
              },
              {
                id: 'sholom-services-HomeCare',
                title: 'Home Care',
                type: 'item',
                url: 'Sholom/TODO',
                end: true,
              },
              {
                id: 'sholom-services-HospiceCare',
                title: 'Hospice Care',
                type: 'item',
                url: 'Sholom/TODO',
                end: true,
              },
              {
                id: 'sholom-services-IndependentLiving',
                title: 'Independent Living',
                type: 'item',
                url: 'Sholom/TODO',
                end: true,
              },
              {
                id: 'sholom-services-LongTermCare',
                title: 'Long Term Care',
                type: 'item',
                url: 'Sholom/TODO',
                end: true,
              },
              {
                id: 'sholom-services-MemoryCare',
                title: 'Memory Care',
                type: 'item',
                url: 'Sholom/TODO',
                end: true,
              },
              {
                id: 'sholom-services-NursingHomeCare',
                title: 'Nursing Home Care',
                type: 'item',
                url: 'Sholom/TODO',
                end: true,
              },
              {
                id: 'sholom-services-OutpatientRehabilitation',
                title: 'Outpatient Rehabilitation',
                type: 'item',
                url: 'Sholom/TODO',
                end: true,
              },
              {
                id: 'sholom-services-PalliativeCare',
                title: 'Palliative Care',
                type: 'item',
                url: 'Sholom/TODO',
                end: true,
              },
              {
                id: 'sholom-services-RespiteCare',
                title: 'Respite Care',
                type: 'item',
                url: 'Sholom/TODO',
                end: true,
              },
              {
                id: 'sholom-services-SeniorLiving',
                title: 'Senior Living',
                type: 'item',
                url: 'Sholom/TODO',
                end: true,
              },              
              {
                id: 'sholom-services-ShortTermCare',
                title: 'Short Term Care',
                type: 'item',
                url: 'Sholom/TODO',
                end: true,
              },
              {
                id: 'sholom-services-Wellness',
                title: 'Wellness',
                type: 'item',
                url: 'Sholom/TODO',
                end: true,
              },
            ],            
            end: true,
          },
          
          {
            id: 'sholom-campus',
            title: 'Campus',
            type: 'item',
            url: 'Sholom/TODO',
            type: 'collapse',
            children: [         
              {
                id: 'sholom-loc-ackerberg',
                title: 'Ackerberg Family Sholom West Campus',
                type: 'item',
                url: 'Sholom/TODO',
                end: true,
              },   
              {
                id: 'sholom-loc-Shaller',
                title: 'Shaller Family Sholom East Campus',
                type: 'item',
                url: 'Sholom/TODO',
                end: true,
              }
            ],
            end: true,
          },  

                
          
          {
            id: 'sholom-bulidings',
            title: 'Buildings',
            type: 'item',
            url: 'Sholom/TODO',
            type: 'collapse',
            children: [
              {
                id: 'sholom-loc-BentsonFamilyAssistedLiving',
                title: 'Bentson Family Assisted Living',
                type: 'item',
                url: 'Sholom/TODO',
                end: true,
              },
              {
                id: 'sholom-loc-DavidFeinberg',
                title: 'David Feinberg Vitality & Aquatics Center',
                type: 'item',
                url: 'Sholom/TODO',
                end: true,
              },
              {
                id: 'sholom-loc-Weinberg',
                title: 'Harry & Jeanette Weinberg Apartments',
                type: 'item',
                url: 'Sholom/TODO',
                end: true,
              },
              {
                id: 'sholom-loc-Knollwood',
                title: 'Knollwood Place Apartments',
                type: 'item',
                url: 'Sholom/TODO',
                end: true,
              },
              {
                id: 'sholom-loc-Hodroff',
                title: 'Leo and Doris Hodroff Pavilion for Memory Care',
                type: 'item',
                url: 'Sholom/TODO',
                end: true,
              },
              {
                id: 'sholom-loc-MenorahWest',
                title: 'Menorah West',
                type: 'item',
                url: 'Sholom/TODO',
                end: true,
              },
              {
                id: 'sholom-loc-PhillipsMemoryCare',
                title: 'Phillips Center for Memory Care',
                type: 'item',
                url: 'Sholom/TODO',
                end: true,
              },
              {
                id: 'sholom-loc-Roitenberg',
                title: 'Roitenberg Family Assisted Living',
                type: 'item',
                url: 'Sholom/TODO',
                end: true,
              },
              {
                id: 'sholom-loc-SholomHomeWest',
                title: 'Sholom Home West',
                type: 'item',
                url: 'Sholom/TODO',
                end: true,
              },
              
              {
                id: 'sholom-loc-ShirleyChapman',
                title: 'Shirley Chapman Sholom Home East',
                type: 'item',
                url: 'Sholom/TODO',
                end: true,
              }
            ]
          }, 

          {
            id: 'sholom-regs',
            title: 'Regulations',
            type: 'item',
            url: 'Sholom/Regulations',
            type: 'collapse',
            children: [         
              {
                id: 'sholom-fed',
                title: 'Federal Regulations',
                type: 'item',
                url: 'Sholom/Regulations/Federal',
                end: true,
              },   
              {
                id: 'sholom-state-mn',
                title: 'State Regulations',
                type: 'item',
                url: 'Sholom/Regulations/State/MN',
                end: true,
              },
            ],
            end: true,
          },

          {
            id: 'sholom-policies-procedures',
            title: 'Policies and Procedures',
            url: 'Sholom/PoliciesAndProcedures',
            type: 'item',          
            end: true,
          },
        ]
      },             
      {
        id: 'sholomSupport',
        title: 'Support',
        auth: ['sholom'],
        type: 'item',        
        url: 'support',
      }
    ]
  },
  
*/

{/* SHOLOM NAVIGATION

          {
            id: 'sholom-services',
            title: 'Services',
            url: 'Sholom/Services',
            type: 'item',
            end: true,
          },

          
          {
            id: 'sholom-campus',
            title: 'Campus',
            type: 'item',
            url: 'Sholom/Campus',
            type: 'item',            
            end: true,
          },  
          {
            id: 'sholom-bulidings',
            title: 'Buildings',
            type: 'item',
            url: 'Sholom/Buildings',
            type: 'item',
            end: true,
          },

*/}

  // BrightSource Free Regulations
const navigationConfig = [

  {
    id: 'sholomNav', 
    title: 'Bright Sources',    
    active: 'square',
    type: 'group',
    icon: 'heroicons-outline:bolt',
    checkPermission: true,
    auth: ['admin', 'sholom'],
    children: [
      
      {
        id: 'sholomBrightSource',
        title: 'Sholom Community Alliance',
        type: 'collapse',
        checkPermission: true,
        url: 'Sholom',
        auth: ['admin', 'sholom'],
        children: [
          {
            id: 'sholom-policies-procedures',
            title: 'Policies and Procedures',
            url: 'Sholom/PoliciesAndProcedures',
            type: 'item',          
            end: true,
          },

          {
            id: 'sholom-regs',
            title: 'Regulations',
            type: 'item',
            url: 'Sholom/Regulations',
            type: 'item',
            end: true,
          }
        ]
      },             
      {
        id: 'sholomSupport',
        title: 'Support',
        auth: ['sholom'],
        type: 'item',        
        url: 'support',
      }
    ]
  },

  {
    id: 'elysianNav', 
    title: 'Bright Sources',    
    active: 'square',
    type: 'group',
    icon: 'heroicons-outline:bolt',
    checkPermission: true,
    auth: ['admin', 'elysian', 'demo'],
    children: [
      {
        id: 'elysianBrightSource',
        title: 'Elysian Senior Homes',
        type: 'collapse',
        checkPermission: true,
        auth: ['admin', 'elysian', 'demo'],
        url: 'Elysian/',
        children: [
          
          {
            id: 'elysian-compentencies',
            dense: true,
            title: 'Competencies',
            type: 'item',
            url: 'Elysian/Competencies',
            end: true,
          },

          {
            id: 'elysian-emergencymanual',
            dense: true,
            title: 'Emergency Manual',
            type: 'item',
            url: 'Elysian/EmergencyManual',
            end: true,
          },

          {
            id: 'elysian-employmentdocs',
            dense: true,
            title: 'Employment Documents',
            type: 'item',
            url: 'Elysian/EmploymentDocuments',
            end: true,
          },

          {
            id: 'elysian-instructions',
            dense: true,
            title: 'Instructions',
            type: 'item',
            url: 'Elysian/Instructions',
            end: true,
          },
          {
            id: 'elysian-pp',
            dense: true,
            title: 'Policies & Procedures',
            type: 'item',
            url: 'Elysian/PoliciesAndProcedures',
            end: true,
          },
          {
            id: 'elysian-regs',
            dense: true,
            title: 'Regulations',
            type: 'item',
            url: 'Elysian/Regulations',
            end: true,
          }         
        ]
      },      
      {
        id: 'elysianSupport',
        title: 'Support',
        auth: ['elysian'],
        type: 'item',        
        url: 'support',
      }
    ]
  },

  {
    id: 'demoBrightSource', 
    title: 'Bright Sources',    
    active: 'square',
    type: 'group',
    icon: 'heroicons-outline:bolt',
    checkPermission: true,
    auth: ['admin', 'demo', 'free'],
    children: [
      {
        id: 'demoGroupRegulationV2',
        title: 'Regulations',        
        type: 'collapse',
        checkPermission: true,
        url: 'Free',
        auth: ['admin', 'demo', 'free'],
        children: [          
          {
            id: 'free-reg-skilled',
            dense: true,
            title: 'Skilled Nursing',
            type: 'item',
            url: 'Free/SNF',
            end: true,
          },
          {
            id: 'free-reg-al',
            title: 'Assisted Living',
            type: 'item',
            url: 'Free/AL',
            end: true,
          },          
        ]
      },      
      {
        id: 'demoLumberjack',
        title: 'Lumberjack',        
        type: 'collapse',
        url: 'lumberjack/clinical',
        auth: ['admin', 'demo'],
        dense: true,
        children: [          
          {
            id: 'demoLumberjackClinical',
            title: 'Clinical',
            type: 'item',
            url: 'lumberjack/clinical',
            auth: ['admin', 'demo'],
            dense: true,
            end: true,
          },
          {
            id: 'demoLumberjackFinancial',
            title: 'Financial',
            auth: ['admin', 'demo'],
            type: 'item',
            url: 'lumberjack/financial',
            dense: true,
            end: true,
          },
        ]
      },
      {
        id: 'demoSupport',
        title: 'Support',
        auth: ['demo'],
        type: 'item',        
        url: 'support',
      }
    ],
  },

  
  // Leading Age
  {
    id: 'leadingAge',
    title: 'Leading Age',    
    active: 'square',
    type: 'group',
    icon: 'heroicons-outline:bolt',
    checkPermission: true,
    auth: ['admin', 'staff'],
    children: [
      {
        id: 'leadingAgelumberjack',
        title: 'Lumberjack',        
        type: 'collapse',
        url: 'lumberjack/clinical',
        auth: 'admin',
        dense: true,
        children: [
          {
            id: 'leadingAgelumberjackClinical',
            title: 'Clinical',
            type: 'item',
            url: 'lumberjack/clinical',
            auth: 'admin',
            dense: true,
            end: true,
          },
          {
            id: 'leadingAgelumberjackFinancial',
            title: 'Financial',
            auth: 'admin',
            type: 'item',
            url: 'lumberjack/financial',
            dense: true,
            end: true,
          },
        ]
      },
      {
        id: 'leadingAgeSupport',
        title: 'Support',
        auth: ['staff'],
        type: 'item',        
        url: 'support',
      }
    ],
  },

  {
    id: 'appsAhi',
    title: 'Advanced Health Institute',    
    active: 'square',
    type: 'group',
    icon: 'heroicons-outline:bolt',
    checkPermission: true,
    auth: ['admin', 'ahi'],
    children: [
      {
        id: 'ahiBrightRegulation',
        title: 'Regulations',
        type: 'collapse',        
        checkPermission: true,
        url: 'Free',
        auth: ['admin', 'ahi'],
        children: [          
          {
            id: 'ahiRegAl',
            dense: true,
            title: 'Skilled Nursing',
            type: 'item',
             url: 'Free/SNF',
            end: true,
          },
          {
            id: 'ahiReg144G',
            title: 'Assisted Living',
            type: 'item',
            url: 'Free/AL',
            end: true,
          },          
        ]
      },
      {
        id: 'ahiSupport',
        title: 'Support',
        auth: ['ahi'],
        type: 'item',        
        url: 'support',
      }
    ]
  },

  {
    id: 'applicationsTools',
    title: 'DEMOS',    
    active: 'square',
    type: 'group',
    icon: 'heroicons-outline:bolt',
    checkPermission: true,
    auth: ['admin'],
    children: [
      {
        id: 'lumberjack',
        title: 'Lumberjack',        
        type: 'collapse',
        url: 'lumberjack/clinical',
        auth: 'admin',
        dense: true,
        children: [
          
          {
            id: 'lumberjackClinical',
            title: 'Clinical',
            type: 'item',
            url: 'lumberjack/clinical',
            auth: 'admin',
            dense: true,
            end: true,
          },
          {
            id: 'lumberjackFinancial',
            title: 'Financial',
            auth: 'admin',
            type: 'item',
            url: 'lumberjack/financial',
            dense: true,
            end: true,
          },
        ]
      },
      {
        id: 'systemTools',
        title: 'Tools',        
        type: 'collapse',
        auth: ['admin'],
        checkPermission: true,
        children: [          
          {
            id: 'toolImportCare',
            title: 'Business Card Import',
            type: 'item',
            url: 'import-card',
            auth: ['admin'],
            end: true,
          },
        ]
      },
      
      {
        id: 'lumberjackSupport',
        title: 'Support',
        auth: ['admin', 'customer-cornerstone'],
        type: 'item',        
        url: 'support',
      }
    ]
  },  
  
];

/* 
  {
    id: 'integration',
    title: 'Configuration',    
    auth: 'admin',
    active: 'square',
    type: 'group',
    icon: 'heroicons-outline:bolt',
    
    children: [
      {
        
        id: 'admin',
        title: 'Administration',     
        type: 'collapse',
              
        children: [
          {
            id: 'prompts',
            title: 'Prompts',     
            type: 'item',
            icon: 'heroicons-outline:chat',
            url: 'prompts',
          },
          {
            id: 'ai',
            title: 'AI',     
            type: 'item',
            icon: 'heroicons-outline:cloud',
            url: 'ai',
          },
          {
            id: 'datamodels',
            title: 'Data Models',     
            type: 'item',
            icon: 'heroicons-outline:table',
            url: 'datamodels',
          },
          {
            id: 'aikeys',
            title: 'Keys',     
            type: 'item',
            icon: 'heroicons-outline:key',
            url: 'keys',
          },
          {
            id: 'Permissions',
            title: 'Permissions',
            type: 'item',
            icon: 'heroicons-outline:lock-closed',
            //url: 'permissions/Members',
          }
        ]
      }
    ]
  }
  */
export default navigationConfig;
