import React, {useContext} from 'react';
import Button from '@mui/material/Button';
import NavLinkAdapter from '@fuse/core/NavLinkAdapter';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import FuseLoading from '@fuse/core/FuseLoading';
import _ from '@lodash';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import Box from '@mui/system/Box';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete from '@mui/material/Autocomplete/Autocomplete';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import IconButton from '@mui/material/IconButton';
import { useDeepCompareEffect } from '@fuse/hooks';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
//import TaskPrioritySelector from './TaskPrioritySelector';
import FormActionsMenu from './SidebarRightMenu';

import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import data from './data';
import PromptContext from './context';

//import { addTask, getTask, newTask, selectTask, updateTask } from '../store/taskSlice';
//import { selectTags } from '../store/tagsSlice';

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  title: yup.string().required('You must enter a name'),
});

function SidebarRight(props) {
  //const task = useSelector(selectTask);
  const { currentPrompt, currentModel, setCurrentModel, setRightSidebarOpen } = useContext(PromptContext);
  
  
  const onClose = () => {
    setRightSidebarOpen(false);
  }

  const task = {};
  const tags = [];
  //const [keyType, setKeyType] = React.useState(keyInfo.type);
  //const [key, setKey] = React.useState(keyInfo.key);
  
  const routeParams = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const keyInfo = data.keys.filter(k => k.id == routeParams.id)[0];

 // const tags = useSelector(selectTags);
  

  
  const onKeyTypeChange = (event) => {
    //setKeyType(event.target.value);
  };
  const { control, watch, reset, handleSubmit, formState, getValues } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  const form = watch();

  /**
   * Update Task
   */
  useDeepCompareEffect(() => {
    /*
    if (!isValid || _.isEmpty(form) || !task || routeParams.id === 'new') {
      return;
    }

    if (!_.isEqual(task, form)) {
      onSubmit(form);
    }
    */
  }, [form, isValid]);

  useEffect(() => {
    if (routeParams.id === 'new') {
      //dispatch(newTask(routeParams.type));
    } else {
      //dispatch(getTask(routeParams.id));
    }
  }, [dispatch, routeParams]);

  useEffect(() => {
    reset({ ...task });
  }, [task, reset]);

  /**
   * Form Submit
   */
  function onSubmit(data) {
    /*
    dispatch(updateTask(data));
    */
  }

  function onSubmitNew(data) {
    /*
    dispatch(addTask(data)).then(({ payload }) => {
      navigate(`/apps/tasks/${payload.id}`);
    });
    */
  }

  /*
  if (_.isEmpty(form) ) { // || !task
    return <FuseLoading />;
  }
  */

  let isChecked = (keyInfo?.enabled == "True") ? true : false;


  return (
    <>
      <div className="relative flex flex-col flex-auto items-center px-24 sm:px-24">
        {/* Sidebar Header */}
        <div className="flex items-center justify-between  w-full  mt-14">
            <Typography
                component={motion.span}
                initial={{ y: -20, opacity: 0 }}
                animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
                delay={500}
                className="text-18 font-dark "
                color="text.primary"
            >
            Prompt Settings
            </Typography>
          <div onClick={onClose} className="flex items-center">
          
            <FuseSvgIcon  >heroicons-outline:x</FuseSvgIcon>

          </div>
        </div>

        <div className="flex flex-col items-start w-full pt-16" >
            <FormGroup aria-label="position" >        
                <FormControlLabel
                    style={{marginLeft: '0px'}}
                    value="start"
                    control={<Switch  color="secondary" checked={isChecked} />}
                    label="Enabled"
                    labelPlacement="start"
                />
            </FormGroup>
        </div>
        
        {/* Name */}
        <div className="flex flex-col items-start w-full mt-16" >
            <Controller
            control={control}
            name="title"
            render={({ field }) => (
                <TextField
                  className=" max-h-auto "
                  //{...field}
                  
                  value={currentPrompt?.Name}
                  id="title"
                  label="Name"
                  error={!!errors.title}
                  helperText={errors?.title?.message}
                  variant="outlined"
                  fullWidth
                  multiline
                  minRows={1}
                  maxRows={3}
                />
            )}
            />
        </div>

        {/* Description */}
        <div className="flex flex-col items-start w-full mt-16" >
            <Controller
            control={control}
            name="description"
            render={({ field }) => (
                <TextField
                  className=" max-h-auto "
                  //{...field}
                  
                  value={currentPrompt?.Description}
                  id="description"
                  label="Description"
                  error={!!errors.title}
                  helperText={errors?.title?.message}
                  variant="outlined"
                  fullWidth
                  multiline
                  minRows={3}
                  maxRows={8}  
                />
            )}
            />
        </div>

        {/* Type */}
        <div className="flex flex-col items-start w-full pt-16" >
            <FormControl sx={{  minWidth: '100%' }}>
                <InputLabel id="demo-simple-select-autowidth-label">Model</InputLabel>
                <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={(keyInfo?.type==null)? 'ChatGPT-3.5': keyInfo?.type}
                    onChange={onKeyTypeChange}
                    label="Engine"
                >
                    <MenuItem value={'ChatGPT-3.5'}>ChatGPT-3.5</MenuItem>
                    <MenuItem value={'ChatGPT-4.0'}>ChatGPT-4.0</MenuItem>                
                </Select>
            </FormControl>
        </div>

        {/* Prompt */}
        <div className="flex flex-col items-start w-full mt-16" >
            <Controller
            control={control}
            name="prompt"
            render={({ field }) => (
                <TextField
                  {...field}
                  label="Messages"
                  value={currentPrompt?.Messages}                
                  id="prompt"
                  onChange={(event, newValue) => {
                      //setKey(newValue);
                    }}
                  error={!!errors.notes}
                  helperText={errors?.notes?.message}
                  variant="outlined"
                  fullWidth
                  multiline
                  minRows={6}
                  maxRows={14}                
                />
            )}
          />
        </div>

        {/* Functions */}
        <div className="flex flex-col items-start w-full mt-16" >
          <Controller
            control={control}
            name="functions"
            render={({ field }) => (
                <TextField
                  {...field}
                  label="Functions"
                  value={currentPrompt?.Functions}                
                  id="functions"
                  onChange={(event, newValue) => {
                      //setKey(newValue);
                    }}
                  error={!!errors.notes}
                  helperText={errors?.notes?.message}
                  variant="outlined"
                  fullWidth
                  multiline
                  minRows={6}
                  maxRows={14}                
                />
            )}
          />
        </div>

    </div>

        {/* Save / Cancel*/}
        <Box
            className="flex items-center mt-40 py-14 pr-16 pl-4 sm:pr-48 sm:pl-36 border-t"
        >
            {/* Create */}
            <Button
            variant="contained"
            color="secondary"
            style={{width: '100px'}}
            //disabled={_.isEmpty(dirtyFields) || !isValid}
            onClick={handleSubmit(onSubmitNew)}
            >
            SAVE
            </Button>

            {/* Cancel*/}
            <Button 
                className="ml-10" 
                color="secondary"
                style={{width: '100px'}}
                onClick={onClose}
                variant="contained"
            >
            CANCEL
            </Button>

            
        </Box>
    </>
  );
}

export default SidebarRight;
