import { useState, useEffect } from 'react';
import { AuthContext }  from './AuthContext';
import firebaseAuth from './auth/services/firebase/firebaseAuthService';
import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { logoutUser, setUser } from 'app/store/userSlice'; // these updates redux state
import history from '@history';

/*
  AutoProvider 
  ------------------
  Wires Authorization functionality for the app, including the following:
  
    - isAuthenticated:                indicates if logged in
    - currentUser:                    authenticated user 
    - createUser:                     creates new user
    - signOut:                        logs out user
    - signInWithEmailAndPassword:     log's in user by email and password
    - sendPasswordReset               send password reset link
    - setPassword                     resetPassword
  
  Docs / Resources:
    - https://github.com/firebase/quickstart-js/blob/master/auth/email-password.ts
*/

function AuthProvider({children}) {
  const [currentUser, setCurrentUser] = useState();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [waitAuthCheck, setWaitAuthCheck] = useState(true);
  const dispatch = useDispatch();

  const useFirebaseAuth = true;

  useEffect(() => {
    firebaseAuth.on('onAutoLogin', (user) => {
            
      if(user) {
        success(user, 'Signed in');
      }
      else {        
        pass('');
      }

    }); // onAutoLogin

    firebaseAuth.on('onLogin', (user) => {
      success(user, 'Signed in');
    });

    firebaseAuth.on('onLogout', () => {      
      pass('Signed out');            
      dispatch(logoutUser());
    });

    firebaseAuth.on('onAutoLogout', (message) => {
      pass(message);      
      dispatch(logoutUser());
    });

    firebaseAuth.on('onNoAccessToken', () => {
      pass();
    });

    firebaseAuth.init();

  }, []); // useEffect

  function signInWithEmailAndPassword(email, password) {

    debugger;
    return new Promise(async (resolve, reject) => {    
      if(useFirebaseAuth){

        firebaseAuth
          .signInWithEmailAndPassword(email, password)
          .then((user) => {
                    
            resolve(user);
            if(user?.homepage != null){            
              setTimeout(() => history.push(user.homepage), 0); 
            }
            else {
              setTimeout(() => history.push('/'), 0); 
            }
          })
          .catch((errors) => {
            reject(errors);
          }
        );
      }
      else {
  
        jwtService
          .signInWithEmailAndPassword(email, password)
          .then((user) => {
            // No need to do anything, user data will be set at app/auth/AuthContext
           
          })
          .catch((errors) => {
            reject(errors);
        });
      }
    }) // promise
  }

  function signOut() {
    firebaseAuth.logout();
    setCurrentUser(null);
    setTimeout(() => history.push('/sign-in'), 1000); 
  }

  function success(user, message) {      

    if (message) {
      dispatch(showMessage({ message, 
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        }}
      ));
    }
    
    Promise.all([
      
      dispatch(setUser(user)), 
      setCurrentUser(user),
      // You can receive data in here before app initialization
      // setTimeout(() => history.push('/'), 1000)
      
    ]).then((values) => {
      
      setWaitAuthCheck(false);
      setIsAuthenticated(true);
    });

  }; // success

  function pass(message) {
    if (message) {
      // dispatch(showMessage({ message }));
    }

    setWaitAuthCheck(false);
    setIsAuthenticated(false);
  } // pass
  
  function sendPasswordReset(email){
    return new Promise(async (resolve, reject) => {    

      firebaseAuth
        .sendPasswordReset(email)
          .then(() => {
            resolve();
          })
          .catch(errors => {
            reject(errors);
          })      
      })
  }

  function resetPassword(code, password){
    return firebaseAuth
      .resetPassword(code, password)
  }

  function createUser(email, password, data){
    return new Promise(async (resolve, reject) => {    
      firebaseAuth
      .createUser(email, password, data)
        .then((user) => {
          
          firebaseAuth.sendVerificationEmailToUser();

          // TODO: 
          // Sync Lead Data

          dispatch(setUser(user)),
          setCurrentUser(user);
          resolve(user);

          if(user?.homepage){
            setTimeout(() => history.push(user?.homepage), 500); 
          }else {
            setTimeout(() => history.push('/'), 500); 
          }
          
        })
        .catch((errors) => {
          reject(errors);
        });
      }
    );      
  } // createUser

  function confirmEmail(code){
    return firebaseAuth.confirmEmail(code);
  }

  function recaptchaVerifier() {
    return firebaseAuth.recaptchaVerifier();
  }

  function signInWithPhoneNumber(phone, recaptchaSignature) {
    return firebaseAuth.signInWithPhoneNumber(phone, recaptchaSignature);
  }

  const handlers = ({
    // user handlers
    currentUser, createUser, isAuthenticated,
    // authentication handlers
    signOut, signInWithEmailAndPassword, confirmEmail,
    // reset handlers
    sendPasswordReset, resetPassword,
    // recaptcha and phone handlers
    recaptchaVerifier, signInWithPhoneNumber
  })

  if(waitAuthCheck){
    return (<FuseSplashScreen />)
  }

  return (<AuthContext.Provider value={handlers}>{children}</AuthContext.Provider>)
}

export default AuthProvider;