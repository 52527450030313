import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FuseUtils from '@fuse/utils';
import Input from '@mui/material/Input';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { useContext, useMemo, useState } from 'react';
import clsx from 'clsx';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Box from '@mui/material/Box';
import { lighten } from '@mui/material/styles';
import ContactListItem from './ContactListItem';
// import { getChat } from '../../store/chatSlice';
// import { selectContacts } from '../../store/contactsSlice';
// import { selectChats } from '../../store/chatsSlice';
import ContactAvatar from './ContactAvatar';
import MainSidebarMoreMenu from './MainSidebarMoreMenu';
import LumberjackContext from '../context';

function ReferralListPanel(props) {
  const { requests, setRequests,  setShowReferralsPanel } = useContext(LumberjackContext);
  
  if(requests == null) return; 

  //const dispatch = useDispatch();
  const chats = []; // useSelector(selectChats);
  const user = []; // useSelector(selectUser);

  const [searchText, setSearchText] = useState('');

  function handleSearchText(event) {
    setSearchText(event.target.value);
  }
  
  return (
    <div className="flex flex-col flex-auto h-full">
      <Box
        className="py-16 px-32 border-b-1"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? lighten(theme.palette.background.default, 0.4)
              : lighten(theme.palette.background.default, 0.02),
        }}
      >
        
        {useMemo(
          () => (
            <div className="flex items-center w-full h-40" >
              <Paper className="flex p-4 items-center w-full px-16 py-4 border-1 h-40 rounded-full shadow-none">
                <FuseSvgIcon color="action" size={20}>
                  heroicons-solid:search
                </FuseSvgIcon>

                <Input
                  placeholder="Filter Referrals"
                  className="flex flex-1 px-8"
                  disableUnderline
                  fullWidth
                  value={searchText}
                  inputProps={{
                    'aria-label': 'Search',
                  }}
                  onChange={handleSearchText}
                />
                
              </Paper>
              <MainSidebarMoreMenu className="-mx-16 ml-8" />
            </div>
          ),
          [searchText]
        )}
        
      </Box>

      <FuseScrollbars className="overflow-y-auto flex-1">
        <List className="w-full">
          {useMemo(() => {
            function getFilteredArray(arr, _searchText) {
              if (_searchText.length === 0) {
                return arr;
              }
              return FuseUtils.filterArrayByString(arr, _searchText);
            }

            /*
            const chatListContacts =
              requests.length > 0 && chats.length > 0
                ? chats.map((_chat) => ({
                    ..._chat,
                    ...requests.find((_contact) => _contact.id === _chat.contactId),
                  }))
                : [];
                  */

            
            const reviewContacts = requests.filter(c => c?.Stage !== null && c.Stage === "Review" && c.Show === true);
            const pendingContacts = requests.filter(c => c?.Stage !== null && c.Stage === "Pending Decision" && c.Show === true);
            const offersContacts = requests.filter(c => c?.Stage !== null && c.Stage === "Offers Made" && c.Show === true);
            const finalizedContacts = requests.filter(c => c?.Stage !== null && c.Stage === "Finalized" && c.Show === true);

            // const filteredContacts = getFilteredArray([...requests], searchText);
            // const filteredChatList = getFilteredArray([...chatListContacts], searchText);
            const filteredReviewContacts = getFilteredArray([...reviewContacts], searchText);
            const filteredPendingContacts = getFilteredArray([...pendingContacts], searchText);
            const filteredOffersContacts = getFilteredArray([...offersContacts], searchText);
            const filteredFinalizedContacts = getFilteredArray([...finalizedContacts], searchText);

            // filteredReviewContacts = filteredChatList;

            const container = {
              show: {
                transition: {
                  staggerChildren: 0.1,
                },
              },
            };

            const item = {
              hidden: { opacity: 0, y: 20 },
              show: { opacity: 1, y: 0 },
            };
            
            return (
              <motion.div
                className="flex flex-col shrink-0"
                variants={container}
                initial="hidden"
                animate="show"
              >
                {/* REVIEW CONTACTS */}
                
                {filteredReviewContacts.length > 0 && (
                  <motion.div variants={item}>
                    <Typography className="font-medium text-20 px-32 py-8" color="secondary.main">
                      REVIEW ({filteredReviewContacts.length})
                    </Typography>
                  </motion.div>
                )}

                {filteredReviewContacts.map((contact, index) => (
                  <motion.div variants={item} key={contact.id}>
                    <div className={clsx(filteredReviewContacts.length !== index + 1 && 'border-b-1')}>
                      <ContactListItem
                        chat
                        contact={contact}
                        //onContactClick={(contactId) => dispatch(getChat(contactId))}
                      />
                    </div>
                  </motion.div>
                ))}

                {/* PENDING CONTACTS */}
                {filteredPendingContacts.length > 0 && (
                  <motion.div variants={item}>
                    <Typography className="font-medium text-20 px-32 py-8" color="secondary.main">
                      PENDING DECISION ({filteredPendingContacts.length})
                    </Typography>
                  </motion.div>
                )}

                {filteredPendingContacts.map((contact, index) => (
                  <motion.div variants={item} key={contact.id}>
                    <div className={clsx(filteredPendingContacts.length !== index + 1 && 'border-b-1')}>
                      <ContactListItem
                        contact={contact}
                        // onContactClick={(contactId) => dispatch(getChat(contactId))}
                      />
                    </div>
                  </motion.div>
                ))}

                {/* OFFERS MADE */}
                {filteredOffersContacts.length > 0 && (
                  <motion.div variants={item}>
                    <Typography className="font-medium text-20 px-32 py-8" color="secondary.main">
                     OFFERS MADE ({filteredOffersContacts.length})
                    </Typography>
                  </motion.div>
                )}

                {filteredOffersContacts.map((contact, index) => (
                  <motion.div variants={item} key={contact.id}>
                    <div className={clsx(filteredOffersContacts.length !== index + 1 && 'border-b-1')}>
                      <ContactListItem
                        contact={contact}
                        // onContactClick={(contactId) => dispatch(getChat(contactId))}
                      />
                    </div>
                  </motion.div>
                ))}

                {/* FINALIZED */}
                {filteredFinalizedContacts.length > 0 && (
                  <motion.div variants={item}>
                    <Typography className="font-medium text-20 px-32 py-8" color="secondary.main">
                      FINALIZED ({filteredFinalizedContacts.length})
                    </Typography>
                  </motion.div>
                )}

                {filteredFinalizedContacts.map((contact, index) => (
                  <motion.div variants={item} key={contact.id}>
                    <div className={clsx(filteredFinalizedContacts.length !== index + 1 && 'border-b-1')}>
                      <ContactListItem
                        contact={contact}
                        // onContactClick={(contactId) => dispatch(getChat(contactId))}
                      />
                    </div>
                  </motion.div>
                ))}

              </motion.div>
            );
          }, [requests, chats, searchText])}
        </List>
      </FuseScrollbars>
    </div>
  );
}


export default ReferralListPanel;