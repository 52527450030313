import { collection, getDocs, getDoc, doc, setDoc, query, where} from 'firebase/firestore';
import { firestore as db } from 'src/app/auth/Firebase';
import { config } from "dotenv"; 
config();

// where docs: https://firebase.google.com/docs/firestore/query-data/queries#web-modular-api

// Collection Names
export const CONTAINER_ROLES = 'User Roles';
export const CONTAINER_ORGANIZATION_ROLES = 'Organization Roles';
export const CONTAINER_USERS = 'Users';
export const CONTAINER_ORGANIZATIONS = 'Organizations';
export const CONTAINER_MAIL = 'mail';

let personalEmails = ['gmail', 'hotmail', 'yahoo', 'aol', 'msn', 'comcast', 'live', 'outlook', 'att', 'cox', 'charter', 'earthlink', 'sky']; // https://email-verify.my-addr.com/list-of-most-popular-email-domains.php

/*
  Organization Functions:
  - getOrgById
  - TODO: getOrgByName
  - TODO: getOrgByDocId

  User Functions:
  - createDbUser
  - getUserDocId
  - getDbUserById 
  - getDbUserByEmail 
  - getDbUserByName
*/

export async function getOrgById(id){
  if(id == null || id == '') return;

  const col = collection(db, CONTAINER_ORGANIZATIONS);
  const q = query(col, where("Id", "==", id)); 
  const querySnapshot = await getDocs(q);
  let results = [];  
  querySnapshot.forEach((doc) => {
    let data = doc.data();
    data.id = doc.id;
    data.Navigation = convertNav(data?.Navigation);
    results.push(data)      
  })
  
  if(results.length > 1){
    console.log(`Multiple orgs exist with id ${id}`)
  } else if(results.length == 1){
    return results[0];
  } else {
    return []
  }
}

function convertNav(nav){
  if(nav == null) return {}  
  try {
    nav = JSON.parse(nav);
  }
  catch(ex){
    console.warn(ex.message);
    return {};
  }
  
  return nav;
}

export async function getOrgByName(name){
  if(name == null || name == '') return;

  const col = collection(db, CONTAINER_ORGANIZATIONS);
  const q = query(col, where("NameLC", "==", name.toLowerCase())); 

  const querySnapshot = await getDocs(q);
  let results = [];
  querySnapshot.forEach((doc) => {
    let data = doc.data();
    data.id = doc.id;
    data.Navigation = convertNav(data?.Navigation);
    results.push(data);
  })

  if(results.length > 1){
    console.log(`Multiple orgs exist with name ${name}`)
  } else if(results.length == 1){
    return results[0];
  } else {
    return []
  }
}

function capitalize(word){
  return word.charAt(0).toUpperCase() + word.substring(1);
}

function mergeDataModels(user, data){

  // New version builds User Firestore DB Record off of user object
  let mergedData = {...user};

  // protect
  delete mergeData['passwordHash'];
  delete mergeData['passwordSalt'];

  // displayName
  let displayName = '';
  if(user?.displayName != null){
    displayName = user.displayName.trim();
  } 
  else if(data?.displayName != null) {
    displayName = data.displayName.trim();
  }
  
  // first and last name
  let firstName = '', lastName = '';
  if(displayName != ''){
    let split = displayName.split(' ');
    firstName = (split.length > 0)? split[0].trim(): '';
    lastName = (split.length > 1)? split[1].trim(): '';
  }

  // orgs
  let orgs = ['free'];
  if(data?.orgs != null && data.orgs?.length > 0){
    orgs = data.orgs;     // note: roles should be lowercase
    if(Array.isArray(orgs)){
      if(orgs.find('free') == null){
        orgs.push('free');
      }
    }else {
      if(orgs.trim() != 'free'){
        orgs = [orgs, 'free'];
      }
    }
  }  

  // let dove = null;
  let dove = window.localStorage.getItem('dove'); // TODO: Get from Cookie
  if(dove != null){
    mergedData.doveId = dove;
  }
  if(firstName){
    mergedData.firstName = capitalize(firstName);
  }
  if(lastName){
    mergedData.lastName = capitalize(lastName);
  }
  
  mergedData.orgs = orgs;
  mergedData.acceptedOnTermsOfUse = new Date();
  mergedData.acceptedOnPrivacy = new Date();  

  // Signup message
  if(data?.message != null){
    mergedData.signupMessage = data.message;
  }

  // Facility Types
  if(data?.facilityTypes != null ){
    if(Array.isArray(data.facilityTypes) && data.facilityTypes.length > 0){
      mergedData.signupFacilityTypes = data.facilityTypes;
    }
    else if(data.facilityTypes.trim().length > 0)
    {
      mergedData.signupFacilityTypes = data.facilityTypes;
    }     
  }
  
  // Signup Interests
  if(data?.interests != null ){
    if(Array.isArray(data.interests) && data.interests.length > 0){
      mergedData.signupInterests = data.interests;
    }
    else if(data.interests.trim().length > 0) {
      mergedData.signupInterests = data.interests;
    }     
  }

  // Signup States
  if(data?.states != null ){
    if(Array.isArray(data.states) && data.states.length > 0){
      userData.signupStates = data.states;
    }
    else if(data.states.trim().length > 0) {
      userData.signupStates = data.states;
    }     
  }


  return userData;
}

export async function createDbUser(user, data){
  try {
    // user.data = normalizeDataModel(user, data);
    let mergedUser = mergeDataModels(user, data);
    await setDoc(doc(db, CONTAINER_USERS, user.uid), mergedUser);
    resolve(mergedUser);
  }
  catch(ex){
    console.log(ex.message);
    throw(ex)
  }
}

// Query Functions
export async function getDocumentById(collection, id) {
  try {
    const docRef = doc(db, CONTAINER_USERS, id);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      console.error(`No document found in ${collection} for id: ${id}`);
      return null;
    }
    return docSnap.data();
  } catch(e) {
    console.error(`Error getting document from ${collection} for id: ${id}`, e);
    return null;
  }
}

export async function getDbUserByEmail(email){  
  if(email == null || email.trim() == '') return;
  
  const col = collection(db, CONTAINER_USERS);
  //const q = query(col, where("Emails", "array-contains", {'Email': 'jonathanasbury@sharepointshop.net', 'EmailType': 'Work'})); 
  // THIS WORKS: const q = query(col, where("Emails", "array-contains", {'Email': 'jonathanasbury@sharepointshop.net', 'EmailType': 'Work'})); 
  // DOES NOT WORK: const q = query(col, where("Emails", "array-contains", {'Email': 'jonathanasbury@sharepointshop.net', 'EmailType': 'Work'})); 
  //const q = query(col, where("Emails", "in", [{'Email': 'jonathanasbury@sharepointshop.net', 'EmailType': 'Work'}])); 
  // THIS WORKS: const q = query(col, where("Email2", "array-contains", 'jonathanasbury@sharepointshop.net')); 
  
  const q = query(col, where("email", "==", email.toLowerCase())); 
  const querySnapshot = await getDocs(q);
  let results = [];
  querySnapshot.forEach((doc) => { // doc.data() is never undefined for query doc snapshots      
    results.push(doc.data())
    //console.log(doc.id, " => ", doc.data());
  })
      
  if(results.length > 1){
    console.warn(`There is more than one user with email ${email}`)
    return results[0];
  }
  else if(results.length == 1){
    return results[0];
  }
  return
}

export async function getDbUserByName(firstName, lastName){
  const col = collection(db, CONTAINER_USERS);

  const q = query(col, where("firstName", "==", firstName), where("lastName", "==", lastName)); // where docs: https://firebase.google.com/docs/firestore/query-data/queries#web-modular-api
  const querySnapshot = await getDocs(q);
  let results = [];
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    results.push(doc.data())
    //console.log(doc.id, " => ", doc.data());
  })
  return results;
}

export async function getDbUserById(id) {
  return getDocumentById(CONTAINER_USERS, id);
}