import React, {lazy} from 'react';
const Layout = lazy(() => import('../modules/ChatApp/ChatAppLayout'));
const ChatApp = lazy(() => import('../modules/ChatApp/ChatApp')); 

const Routes = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'Free',
      element: <Layout />,
      auth: ['admin', 'free', 'demo', 'elysian', 'sholom'],      
      children: [
        {
          path: '',
          auth: ['admin', 'free', 'demo', 'elysian', 'sholom'],
          element: <ChatApp 
            org={{key: "gov", name: "US Regulations"}}
            collection='free-1'
            level1='Regulations'
            pageTitle="Complementary Regulations"
            pageDescription="Supporting Long-Term Care Communities at Dovaxis"
            chatHeader={<span>Free Regulations</span>}
          />,
        },
        {
          path: 'SNF',
          auth: ['admin', 'free', 'demo', 'elysian', 'sholom'],
          element: <ChatApp 
            collection='free-1'
            org={{key: "gov", name: "US Regulations"}}
            namespace='free'
            keywords="snf"
            level1='Regulations'            
            pageTitle="Skilled Nursing Regulations"
            pageDescription="Supporting nurses nationwide"
            chatHeader={<span>Skilled Nursing</span>}
          />,
        },
        {
          path: 'AL',
          auth: ['admin', 'free', 'demo', 'elysian', 'sholom'],
          element: <ChatApp 
            namespace='free'
            org={{key: "gov", name: "US Regulations"}}
            keywords="al"
            collection='free-1'
            level1='Regulations'            
            pageTitle="Assisted Living Regulations"
            pageDescription="BrightSources for Care Specialists by Dovaxis"
            chatHeader={<span>Assisted Living</span>}
          />,
        }
      ],
    },
  ],
};

export default Routes;