import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { IconButton, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { collection, getDocs } from "firebase/firestore";
import { db } from "src/app/auth/Firebase";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 1,
  p: 4,
  width: "-webkit-fill-available",
};

function Training() {
  const [videolist, setvideoList] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const handleClose = () => setOpen(false);

  const handleCardClick = (card) => {
    setSelectedCard(card);
    setOpen(true);
  };

  const fetchYouTubeThumbnail = async (Url) => {
    try {
      const videoId = extractVideoId(Url);
      return `https://img.youtube.com/vi/${videoId}/0.jpg`; // Using YouTube API to get the thumbnail
    } catch (error) {
      console.error("Error fetching YouTube thumbnail:", error);
      return "assets/images/pages/support/shifting-img.png"; // Default image in case of error
    }
  };

  const extractVideoId = (url) => {
    const regex =
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/i;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const videosCollection = collection(db, "Videos");
        const videosSnapshot = await getDocs(videosCollection);
        const videosList = await Promise.all(
          videosSnapshot.docs.map(async (doc) => {
            const data = doc.data();
            const thumbnailUrl = await fetchYouTubeThumbnail(data.Url);
            return { ...data, thumbnailUrl };
          })
        );
        setvideoList(videosList);
      } catch (error) {
        console.error("Error fetching videos: ", error);
      }
    };

    fetchVideos();
  }, []);

  return (
    <div
      style={{ width: "100%" }}
      className="flex flex-wrap align-start gap-10 p-10"
    >
      {videolist.map((video, index) => (
        <Card
          key={index}
          sx={{
            width: "100%",
            maxWidth: 370,
            borderRadius: 2,
            cursor: "pointer",
          }}
          onClick={() => handleCardClick(video)}
        >
          <CardMedia
            sx={{ height: 200 }}
            image={
              video.thumbnailUrl ||
              "assets/images/pages/support/shifting-img.png"
            }
            title="YouTube Thumbnail"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {video.Name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {video.VideoDescription}
            </Typography>
          </CardContent>
        </Card>
      ))}

      {/* Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            onClick={handleClose}
            size="large"
            style={{ position: "absolute", top: 10, right: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            {selectedCard && selectedCard.Name}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ my: 2 }}>
            {selectedCard && selectedCard.VideoDescription}
          </Typography>

          <div
            style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}
          >
            <iframe
              id="youtube-player"
              type="text/html"
              width="100%"
              height="100%"
              src={selectedCard && selectedCard.Url}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>

          <div className="flex gap-5 justify-end mt-5">
            <Button
              className="rounded my-5 bg-blue-500"
              variant="contained"
              color="primary"
              onClick={handleClose}
            >
              Close
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default Training;
