
import { Pinecone } from "@pinecone-database/pinecone";
import OpenAI from 'openai';
import { config } from "dotenv";
config();


const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  organization: process.env.REACT_APP_OPENAI_ORG_ID,
  dangerouslyAllowBrowser: true
});

// additionalHeaders: ['Access-Control-Allow-Origin: https://app.dovaxis.com ']
// CURRENT: additionalHeaders: {'Access-Control-Allow-Origin': 'https://app.dovaxis.com'}

const pinecone = new Pinecone(
  { apiKey: process.env.REACT_APP_PINECONE_API_KEY
  });

async function createQuestionsByDocTitle(namespace, docTitle, vectorCount=3) {
  
  let dbData = await searchPinconeByDocTitle(namespace, docTitle, vectorCount);  
  let result = await createQuestionsWithGPT(dbData, vectorCount);
  return result;
}

async function createQuestionsByFileName(namespace, fileName, vectorCount=1) {
  
  let dbData = await searchPinconeByFileName(namespace, fileName, vectorCount);  
  let result = await createQuestionsWithGPT(dbData, vectorCount);
  return result;
}

async function createQuestionsByNamespace(namespace, vectorCount=1) {
  
  let dbData = await searchPinconeByNamespace(namespace, vectorCount);  
  let result = await createQuestionsWithGPT(dbData, vectorCount);
  return result;
}

//
// createQuestionsWithGPT
// 
async function createQuestionsWithGPT(vectors, vectorCount=1) {

  if(vectors == null || vectors.length == 0) return [];

  let vectorData = '';
  for(let i=0; i<vectors.length;i++){
    if(i>vectorCount) break;
    vectorData +=  vectors[i].metadata.text + " ";
  }  
  
  let sysPrompt = `You are an AI agent functioning as a Q&A bot within a retrieval augmented generation system. Your mission is to generate sample questions to guide the user by showing them a well structured question.

    Your task is to generate three simple and concise questions that can be answered easily from the source material provided. Use simple and clear language and keep the questions short. Proced now by generating  bulleted list of three questions. Use "-" for the bullet points. 
  `;

  let userPrompt = `Source Material: ${vectorData}`;


  try {
    // gpt-3.5-turbo-16k
    const completion = await openai.chat.completions.create({
      model: "gpt-3.5-turbo-1106",
      messages: [
          {role: "system", content: sysPrompt},
          {role: "user", content: userPrompt}
      ],
      temperature: .1,
      max_tokens: 3000,
      top_p: 1
    });
    
    let result = completion.choices[0].message.content;    
    let split = result.toString().split("\n");  
    let examples = [];
    for(var i=0;i<split.length;i++){
      let cleaned = split[i].replace('-', '').trim();
      if(cleaned != ''){
        examples.push(cleaned);
      }
    }
    return examples;
  } 
  catch(ex) {
    console.log(ex.message);
  }
}


//
// searchPinconeByDocTitle
// 
async function searchPinconeByDocTitle(namespace, docTitle, vectorCount=2) {
  
   
  try {
    const index = pinecone.index(process.env.REACT_APP_PINECONE_INDEX);     
    let result = await createEmbedding(docTitle); // get embeddings

    // match namespace + plus either doc-title (old model) or docTitle (new model)       
    let filterByMetadata = {
      "$or": [{"namespace": {"$eq": namespace}, "doc-title": {"$eq": docTitle}}, {"namespace": {"$eq": namespace}, "titleShort": {"$eq": docTitle}}]
    }  

    let qry = {
      topK: vectorCount, // Adjust this based on your needs
      vector: Array.from(result.data[0].embedding),
      filter: filterByMetadata,
      includeMetadata: true
    }    
    const response = await index.query(qry);        
    const relevantVectors = response.matches;    
    return relevantVectors;
  } 
  catch (err) {
    console.error('Pinecone query error:', err.message);
    return []; 
  }  
}

//
// searchPinconeByFileName
// 
async function searchPinconeByFileName(namespace, fileName, vectorCount=2) {
  
   
  try {
    const index = pinecone.index(process.env.REACT_APP_PINECONE_INDEX);     
    let result = await createEmbedding(fileName); // get embeddings

       // Use the question as the query vector

    //let filterByMetadata = {'$and': [{"namespace": namespace}, {"sourceFileName": fileName}]};        
    let filterByMetadata = {"namespace": {"$eq": namespace}, "sourceFileName": {"$eq": fileName}};        
    
    let qry = {
      topK: vectorCount, // Adjust this based on your needs
      vector: Array.from(result.data[0].embedding),
      filter: filterByMetadata,
      includeMetadata: true
    }    
    const response = await index.query(qry);        
    const relevantVectors = response.matches;    
    return relevantVectors;
  } 
  catch (err) {
    console.error('Pinecone query error:', err.message);
    return []; 
  }  
}

//
// searchPinconeByNamespace
// 
async function searchPinconeByNamespace(namespace,  vectorCount=2) {
  
   
  try {
    const index = pinecone.index(process.env.REACT_APP_PINECONE_INDEX);     
    let result = await createEmbedding(namespace); // get embeddings

       // Use the question as the query vector

    //let filterByMetadata = {'$and': [{"namespace": namespace}, {"sourceFileName": fileName}]};        
    let filterByMetadata = {"namespace": {"$eq": namespace}};        
    
    let qry = {
      topK: vectorCount, // Adjust this based on your needs
      vector: Array.from(result.data[0].embedding),
      filter: filterByMetadata,
      includeMetadata: true
    }    
    const response = await index.query(qry);        
    const relevantVectors = response.matches;    
    return relevantVectors;
  } 
  catch (err) {
    console.error('Pinecone query error:', err.message);
    return []; 
  }  
}

async function ask(question, namespace, vectorCount=1, language) {

  debugger;
  console.log(`question: ${question}`);  
  console.log(`vectorCount: ${vectorCount}`);

  let vectors = null;
  
  try {
    vectors = await searchPinecone(question, namespace, vectorCount);
  }
  catch(ex){
    console.error(`An error occured in ask.searchPinecone: ${ex.message}`);
    throw ex;
  }
  let response = {answer: '', vectors: vectors.answerVectors, requestedVectors: vectorCount, question, questionEmbedding: vectors.questionEmbedding};

  console.log(`answerVectors: ${JSON.stringify(vectors.answerVectors)}`);
  console.log(`language: ${JSON.stringify(language)}`);

  if (vectors.answerVectors.length == 0) {
    response.answer = 'No relevant results.';
  } 
  else {    
      
    let reply = null;

    try {
      reply = await answerQuestionWithGPT(vectors.answerVectors, question, vectorCount, language);
    }
    catch(ex){
      console.error(`An error occured in ask.answerQuestionWithGPT: ${ex.message}`);
      throw ex;
    }

    console.log(`answer: ${JSON.stringify(reply)}`);

    for(const prop in reply){
      response[prop] = reply[prop];
    }

  }  
  return response;
}

//
// answerQuestionWithGPT
// 
async function answerQuestionWithGPT(vectors, question, vectorCount=1, language) {

  let vectorData = '';
  for(let i=0; i<vectors.length;i++){
    if(i>vectorCount) break;
    vectorData +=  vectors[i].metadata.text + " ";
  } 
  
  console.log(`answerQuestionWithGPT Question: ${vectorData}`);

  let plusLanguage = '';
  if(language.id != 'en'){
    plusLanguage = `Return response in ${language.title}.`
    console.log(`answerQuestionWithGPT plusLanguage ${plusLanguage}`);
  }

  // Original Prompt
  let sysPrompt = `You are an AI agent functioning as a Q&A bot within a retrieval augmented generation system. 
  Your mission is to answer the users question using only the context provided.
  ${plusLanguage}
  If the question can be answered with the context provided answer as follows:
  ContextTrue: (Insert a thorough answer to the question) and then
  (Insert a bulleted line for each answer direct quote.  Prefix section with 'References:'"  

  Otherwise respond:
  "ContextFalse: (Insert three new question of your own based only on the CONTEXT provided )" and then "Apology: (Insert an apology for not being able to find the answer and then suggest the user rephrase the question)"`;


  // New Prompt
  let sysPromptNew = `
  You are an AI agent functioning as a Q&A bot within a retrieval augmented generation system. Your mission is to answer the users question using only the context provided.

  If the question can be answered with the context provided answer as follows:

  "ContextTrue: (Insert your answer to the question) and a bullet point list of references prefixed with "References:" 
  (Insert exact quotes from the context that support the answer.  )"

  CRITICAL INFORMATION: When providing references you must exactly reproduce them as shown in the original context, even if the source is grammatically or syntactically incorrect.  References must only be at most a single sentence in length.

  Otherwise respond:
  "ContextFalse: (Insert three new question of your own based only on the CONTEXT provided )" and then "Apology: (Insert an apology for not being able to find the answer and then suggest the user rephrase the question)"
  
  CRITICAL REMINDER: When providing references you must exactly reproduce them as shown in the original context, even if the source is grammatically or syntactically incorrect. References must only be at most a single sentence in length.
  `

  console.log(`answerQuestionWithGPT sysPrompt:`);
  console.log(sysPrompt);

  const userPrompt = `Question: ${question}
  CONTEXT: ${vectorData}
  `;  
  
  console.log(`answerQuestionWithGPT userPrompt:`);
  console.log(userPrompt);

  try {
    // gpt-3.5-turbo-16k
    // gpt-4-0613
    // gpt-3.5-turbo-1106
    // gpt-3.5-turbo-0125
    const completion = await openai.chat.completions.create({
      model: "gpt-3.5-turbo-0125",
      messages: [
          {role: "system", content: sysPrompt},
          {role: "user", content: userPrompt}
      ],
      temperature: .1,
      max_tokens: 3000,
      top_p: 1
    });

    console.log(`answerQuestionWithGPT response:`);
    console.log(completion.choices[0].message.content);
    let result = convertResponse(completion.choices[0].message.content);    
    return result;
  } 
  catch(ex) {
    console.log(`answerQuestionWithGPT error: ${ex.message}`);
  }
}

//
// searchPinecone
// 
async function searchPinecone(question, namespace, vectorCount=1) {
  
  const index = pinecone.index(process.env.REACT_APP_PINECONE_INDEX);
  let result = await createEmbedding(question); // get embeddings
  try {
    const response = await index.query({
      vector: Array.from(result.data[0].embedding), // Use the question as the query vector
      topK: vectorCount, // Adjust this based on your needs
      filter: { namespace: { '$eq': namespace }},
      includeMetadata: true
    });

    const relevantVectors = response.matches;
    //return relevantVectors;

    return {
      questionEmbedding: result.data[0].embedding,
      answerVectors: relevantVectors
    }

  } catch (error) {
    console.error('Pinecone query error:', error);
    return [];
  }

}

//
// createEmbedding
// 
async function createEmbedding(textToEmbed){
  let result = await openai.embeddings.create(
    {
    model: 'text-embedding-ada-002',
    input: textToEmbed.trim(),
  });
  return result;
}


//
// Convert Response
// 

function parseAnswerFound(response){
  let answer = response.replace('ContextTrue:', '').replace('References:', '').trim(); 

  let reader = new Parser();
  let writer = new HtmlRenderer();
  let parsed = reader.parse(answer); 
  let html = writer.render(parsed);

  return {answered: true, references: [], answer: (<div style={{all:'inherit'}} dangerouslySetInnerHTML={{ __html: html }} />)}
}


function parseAnswerFound_ORIGINAL(response){
  let references = [];
  let refSection = 'References:';
  let contextSection = 'ContextTrue:';
  let refLoc = response.indexOf(refSection);  
  let answerText = '';

  if(refLoc == -1){
    answerText = response.replace(contextSection, '').trim();    
  }
  else {
    
    // get Answer
    answerText = response.substr(0, refLoc).replace(contextSection, '').trim();
    
    // get References
    let refText = response.substr(refLoc + refSection.length).trim();    
    refText = refText.replaceAll('(Context)', '').trim();
    let split = refText.split('\n')

    split.forEach((ref, idx) => {
      let val = ref.trim();      
      if(val.startsWith('-')){
        val = val.substr(1).trim();
      }
      if(val.startsWith('\"')) {
        val = val.substr(1).trim();
      }
      if(val.endsWith('\"')){
        val = val.substr(0, val.length - 1).trim();
      }
      // temp: remove sentance close since gpt changes it
      if(val.endsWith('.') || val.endsWith('!') || val.endsWith(';') || val.endsWith('?')){
        val = val.substr(0, val.length - 1).trim();
      }

      if(val != ''){
        references.push(val)
      }
    })
  }
  
  return {answered: true, references, answer: answerText}
}

function parseAnswerMissing(response){
  let contextSection = 'ContextFalse:';
  let apologySection = 'Apology:';
  
  let loc = response.indexOf(apologySection);
  let apology = response.substr(loc + apologySection.length).trim();
  
  let examples = response.substr(contextSection.length, loc - contextSection.length).trim();
  examples = examples.split('\n');

  for(var i = 0; i < examples.length; i++){
    loc = examples[i].indexOf('.');
    examples[i] = examples[i].substr(loc + 1).trim();
  }
  
  return {answered: false, answer: apology, examples}  
}

function convertResponse(response){
  let result = null;  

  try {
    if(response.indexOf('ContextTrue:') > -1){
      result = parseAnswerFound(response);
    }
    else {
      result = parseAnswerMissing(response);
    }
  }
  catch(ex){
    console.warn(`An error occured converting ChatGPT Response: ${ex.message}`);
    result = {error: true, answer: '', answered: false, response: {}}
  }
 
  return result;
}
export default {ask, createQuestionsByFileName, createQuestionsByNamespace, createQuestionsByDocTitle};