import React, {useEffect} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import parse, { domToReact } from 'html-react-parser';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
//import { marked } from "https://cdn.jsdelivr.net/npm/marked/lib/marked.esm.js";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { styled, alpha } from '@mui/material/styles';

import { getReferenceHtml } from './data';

const VIEW_FOCUSED = 'Focused';
const VIEW_EXPANDED = 'Expanded';
const SHOW_ALL = 'Show All'
const HIGHLIGHT_ALL = 'Highlight All';

// Questions:
//  What is our Respiratory Protection Policy?
// What kind of diseases does the respiratory protection program cover?
// What steps should be taking to return from an evacuation?

let expandedText = ``


function ReferenceDialog(props) {

  const {open, vector, setShowDialog, chat} = props;
  const [ view, setView ] = React.useState(VIEW_EXPANDED);
  const [ highlight, setHighlight ] = React.useState(true);
  const [ currentReference, setCurrentReference] = React.useState(SHOW_ALL);
  const [ currentFile, setCurrentFile ] = React.useState('');


  function handleClose() {    
    setShowDialog(false);
  }

  if(Array.isArray(chat?.response?.vectors) && chat?.response?.vectors.length > 0 ){
    let fileName = chat?.response?.vectors[0].metadata?.sourceFileName;
    let titleShort = chat?.response?.vectors[0].metadata?.titleShort;
    
    if(fileName != null){
      if(currentFile != fileName){            
        setCurrentFile(fileName);
        getReferenceHtml(chat.namespace, fileName, titleShort).then((data) => {
          if(data == null) return;        
          expandedText = data.html;
        })
      }
    }
  }

  function cleanText(text){
    if(text == null) return '';
    text = text.replace(/Cornerstone Nursing and Rehab Center/gi, 'The Facility'); // case insensitive 
    text = text.replace(/Cornerstone/gi, 'The Facility');
    text = text.replace('(CNRC)', '');
    text = text.replace(/CNRC/gi, '');
    text = text.replace(/Nursing and Rehab Center/gi, '');
    text = text.replace('  ', ' ');
    return text;
  }
  
  let contentTitle = '';
  if(vector?.metadata['content-title']){
    contentTitle = vector?.metadata['content-title'];
    if(contentTitle == null)
      contentTitle = '';
    else
    contentTitle += ': ';
      //contentTitle += ': &nbsp;';
  }else {
    contentTitle = '';
  }

  contentTitle = cleanText(contentTitle);
  expandedText = cleanText(expandedText);

  if(!open)
    return null;
  else
    return (<Dialog
      open={open}
      maxWidth={"xl"}  // or lg
      onClose={handleClose}
      aria-labelledby="reference-dialog-title"
      aria-describedby="reference-dialog-description"
      PaperComponent={PaperComponent}
    >
      
        <DialogTitle id="reference-dialog-title"  style={{marginBottom: '8px', borderBottom: 'solid .5px #9d9898'}}>
          {contentTitle}{vector?.metadata['doc-title']}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="reference-dialog-description">
            <div style={{all: 'unset'}}>
              {parse(getReference(chat, highlight, currentReference))}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{marginBottom: '8px', borderTop: 'solid .5px #9d9898'}}> 
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
              <Grid item >
                <ViewButton view={view} setView={setView} />              
                <HighlightSelector highlight={highlight} setHighlight={setHighlight} />
                
              </Grid>
              <Grid item >                  
                <ReferenceSelector chat={chat} view={view} currentReference={currentReference} setCurrentReference={setCurrentReference} highlight={highlight} />
              </Grid>
              {/*
              <Grid item l={2} xs={2}>    
                               
                <GetMoreButton />
                
                <Button variant='contained' style={{ width: '120px', marginLeft: '20px'}} >Edit</Button>
              </Grid>
              */}
              <Grid item >
                
              </Grid>
              <Grid item  style={{flexGrow: 6, flexShrink: 1, textAlign: 'right', marginRight: '30px'}}>
                <Button onClick={handleClose} variant='contained' style={{ width: '120px'}} autoFocus>Close</Button>
              </Grid>
            </Grid>
          </Box>
        </DialogActions>
    </Dialog>
    )

  function getReference(chat, highlight, currentReference) {
    if(view == 'Focused')
      return getChatReference(chat);
    else 
      return getVectorReference(chat, highlight, currentReference);
  }

} // ReferenceDialog Component


function PaperComponent(props) {
  return (
    <Draggable
      handle="#reference-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      
    >
      <Paper {...props} style={{resize: 'both'}} />
    </Draggable>
  );
}



function getChatReference(chat) {

  return chat?.response?.answer;

}


function getVectorReference(chat, highlight, currentReference) {

  let html = expandedText;
  let references = chat.response.references;

  if(highlight || currentReference == SHOW_ALL){
    references.forEach((ref, idx) => {
      html = html.replace(ref, `<span id="highlight${idx}" style="background-color:#f9f996">${ref}</span>`)
    });
    
  }
  else {
    
    try {

      let id = currentReference.replace('Reference ', '').trim();
      id = parseInt(id);
      if(id == 0){
        id = references.length;
      }else {
        id--;
      }
      let ref = references[id];
      html = html.replace(ref, `<span id="highlight${id}" style="background-color:#f9f996">${ref}</span>`)
      // document.getElementById(`highlight${id}`).scrollIntoView({ behavior: 'smooth' , block: 'center'})

    }catch(ex){
      console.warn(`Invalid ID for ${currentReference}.`)
    }
    
  }
  
  return html;

}

function HighlightSelector(props) {

  const { highlight, setHighlight } = props;
  const label = { inputProps: { 'aria-label': 'Highlight' } };

  function onChange(){    
    setHighlight(!highlight)
  }

  let labelText =HIGHLIGHT_ALL;
  if(highlight == false){
    labelText = 'Highlight Selected';
  }
  return (<FormControlLabel control={<Checkbox checked={highlight}  onChange={onChange}  />} label={labelText} {...label}  />)

}

function ReferenceSelector(props) {

  const { chat, view, currentReference, setCurrentReference, highlight} = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const open = Boolean(anchorEl);

  let isDisabled = false;
  if(view == VIEW_FOCUSED){
    isDisabled = true;
  }

  const handleClick = (event) => {
    
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (key) => {
    
    setAnchorEl(null);

    if(typeof key == 'object') return; // user canceled out of drop down

    if(key == -1){
      setCurrentReference(SHOW_ALL);
      setTimeout(() => {      
        let el = document.getElementById('highlight0');
        if(el != null)
          el.scrollIntoView({ behavior: 'smooth' , block: 'center'})
      }, 500)
    }
    else {
      //let reference = chat.response.references[key];
      setCurrentReference(`Reference ${key + 1}`);
      setTimeout(() => {      
        let el = document.getElementById(`highlight${key}`);
        if(el != null)
          el.scrollIntoView({ behavior: 'smooth' , block: 'center'})
      }, 500)
    }
    
  };

  function MoveBack(){
    let references = chat.response.references;
    let id = -1;
    
    // get previous id
    if(currentReference == SHOW_ALL){ // loop across references, 
      id = references.length;
    }else {
      try {
        id = currentReference.replace('Reference ', '').trim();      
        id = parseInt(id);
        if(id == 1){    // on last, move to highest
          id = references.length ; 
        }else{
          id--;
        }
      }
      catch(ex){
        console.warn(`Failed to get Previous Id from ${currentReference}.`)
      }
    }

    setCurrentReference(`Reference ${id}`);

    if(id != -1){
      setTimeout(() => {      
        let el = document.getElementById(`highlight${id}`);
        if(el != null)
          el.scrollIntoView({ behavior: 'smooth' , block: 'center'})
      }, 500)
    }

  } // MoveBack

  function MoveForward(){
    let references = chat.response.references;
    let id = -1;

    // get next id
    if(currentReference == SHOW_ALL){ // loop across references, 
      id = 0;
    }
    else {
      try {      
        id = currentReference.replace('Reference ', '').trim();      
        id = parseInt(id);
        if(references.length > id){
          //id++; // indexing already moved us forward
        }else {
          id = 0; // start over
        }
      }
      catch(ex){
        console.warn(`Failed to get Next Id from ${currentReference}.`)
      }
    }

    setCurrentReference(`Reference ${id + 1}`);
  
  

  if(id != -1){
    setTimeout(() => {      
      let el = document.getElementById(`highlight${id}`);
      if(el != null)
        el.scrollIntoView({ behavior: 'smooth' , block: 'center'})
    }, 500)
  }

  } // MoveForward

 

  return (
    <div>
      <IconButton disabled={isDisabled} aria-label="delete" color="primary" onClick={MoveBack}>
        <KeyboardArrowLeftIcon  />
      </IconButton>
      <Button
        id="reference-selector-button"
        aria-controls={open ? 'reference-selector-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        disabled={isDisabled}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {currentReference}
      </Button>
      <IconButton aria-label="delete" color="primary" onClick={MoveForward} disabled={isDisabled}>
        <KeyboardArrowRightIcon />
      </IconButton>
      <StyledMenu
        id="reference-selector-menu"
        MenuListProps={{
          'aria-labelledby': 'reference-selector-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleClose(-1)} >          
          <strong>Show All</strong>
        </MenuItem>
        {chat?.response?.references?.map((reference, idx) => 
         <MenuItem key={idx} onClick={() => handleClose(idx)} >          
          <strong>{`Reference ${idx + 1}`}</strong>{`: ${reference}`}
          </MenuItem>
        )}
        
              
      </StyledMenu>
    </div>
  );
} // 

function GetMoreButton(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        onClick={handleClick}
        style={{minWidth: '250px'}}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Get More References
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} disableRipple>          
          Find More References
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>          
          Open Original Reference
        </MenuItem>
              
      </StyledMenu>
    </div>
  );
}

function ViewButton(props) {
  const {view, setView} = props;

  const handleClick = (event) => {
    event.stopPropagation();
    // event.nativeEvent.stopImmediatePropagation();
    
    if(view == VIEW_FOCUSED){
      setView(VIEW_EXPANDED);
    }
    else{
      setView(VIEW_FOCUSED);
    }
  };

  return (    
    <FormControlLabel  style={{marginLeft: '10px'}} control={<Switch defaultChecked onClick={handleClick} />} label={view} />    
  );
}


const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));



export default ReferenceDialog;
