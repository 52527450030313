
import { useState, useRef, useEffect } from 'react';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import clsx from 'clsx';
import LoadingDove from './DoveIconLoading';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import DocChat from '../docChatV3';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { useSelector } from 'react-redux';
import { selectCurrentLanguage, selectLanguages } from 'app/store/i18nSlice';
import { saveSearch } from '../../data';
import Recommendations from '../components/Recommendations';
import References from './References';
import useChatContext from '../ChatContext';
import ChatDocMessage from './ChatDocMessage';

let FLAG_USER = 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df';
let FLAG_SYS = '9d3f0e7f-dcbd-4e56-a5e8-87b8154e9edf';

const user = {
  "id": "cfaad35d-07a3-4447-a6c3-d8c3d54fd5df",
  "name": "Brian Hughes",
  "email": "hughes.brian@company.com",
  "status": "online",
  "avatar": "assets/images/avatars/brian-hughes.jpg",
  "about": "Hi there! I'm using FuseChat."
}

function Messages(props){
  const chatRef = useRef(null);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const [ vectorCount, setVectorCount ] = useState(2);
  const { chatSession, currentUser } = useChatContext();
  
  const currentLanguage = useSelector(selectCurrentLanguage);

  useEffect(() => {
    
    if (chatSession.chat != null && chatSession.chat.length > 0) {
      setTimeout(scrollToBottom);
    }    

    // postQuestion?      
    if(chatSession?.question?.current != null && chatSession?.question?.current != ''){
      clearRecommendations();
      // TODO: Handle this so it preserves 
      chatSession.question.previous = chatSession.question.current;
      chatSession.question.current = '';      // prevent additional calls
      postQuestion(chatSession.question.previous);    
      
    }
  }, [chatSession]);

  let chat = chatSession.chat;

  function scrollToBottom() {
    if (!chatRef.current) {
      return;
    }
    chatRef.current.scrollTo({
      top: chatRef.current.scrollHeight,
      behavior: 'smooth'
    })
  }

  function isFirstMessageOfGroup(item, i) {
    return i === 0 || (chat[i - 1] && chat[i - 1].contactId !== item.contactId);
  }

  function isLastMessageOfGroup(item, i) {
    return i === chat.length - 1 || (chat[i + 1] && chat[i + 1].contactId !== item.contactId);
  }

  function postDocMessage(searchTerms){

    if(searchTerms.trim() == '') return;
    let split = searchTerms.split(' ');
    if(split.length > 3) return;

    chat.push({
      "id": chat.length + 1,
      "chatId": "",
      "contactId": currentUser.uid, // currentUser.uid,
      "type": 'doc',
      "value": searchTerms
    })

    return true;
  }

  function postQuestion(question) {

    let ask = (typeof question == "string")? question : '';

    if(ask.trim() == '') return;
    if(postDocMessage(question, currentUser) == false) {
      chatSession.syncCache();
      return;
    }

    // push ask
    chat.push({
      "id": chat.length + 1,
      "chatId": "",
      "contactId": currentUser.uid, // currentUser.uuid,
      "value": ask,
      "createdAt": new Date()
    })

    

    // push thinking prompt
    chat.push({
      "id": chat.length + 1,
      "chatId": FLAG_SYS,
      "type": "loading"
    })

    chatSession.showRecommendations = false;
    chatSession.syncCache();

    DocChat.ask(ask, chatSession.namespace, vectorCount, currentLanguage)
      .then( response => 
        {
          chat.pop();  // remove thinking
                 
          saveSearch(ask, currentUser, response?.answered, response.questionEmbedding, currentLanguage, chatSession.namespace, response).then( document => {
            //alert(document.id);
          });

          // push answer
          chat.push({
            "id": chat.length + 1,
            "question": ask,
            "namespace": chatSession.namespace,
            "chatId": FLAG_SYS,
            "contactId": "",
            "value": response.answer,
            "response": response,
            "createdAt": new Date()
          })

          chatSession.syncCache();

          if(response?.answered == false) {
            chatSession.recommendations = response.examples;
            chatSession.showRecommendations = true;
            chatSession.syncCache();
          }
        })      
      .catch( response =>
      {
        console.log('saveSearch error')
        console.log(response)
        saveSearch(ask, currentUser, false, response.questionEmbedding, currentLanguage, chatSession.namespace).then( document => {
          //alert(document.id);
        });
      }); // DocChat.ask
     
  } // postQuestion

  if (!user || !chat ) {
    return null;
  }
  
  let chatWidth = {
    width: '60%',
    alignSelf: 'center',
    backgroundColor: 'white'
  }

  if(isMobile)
    chatWidth.width = '100%';


  function applyRecommendation(question) {
    clearRecommendations();
    postQuestion(question);
  }

  function clearRecommendations(){
    chatSession.recommendations = [];
    chatSession.showRecommendations = false;
    chatSession.syncCache();
  }

  return(
    <>
    <div className="flex flex-auto h-full min-h-0 w-full " >
      <div className={clsx('flex flex-1 z-10 flex-col relative', props.className)}>
        <div ref={chatRef} className="flex flex-1 flex-col overflow-y-auto" style={{marginBottom: '90px'}}>
          {chat?.length > 0 && (
            <div className="flex flex-col pt-16 px-16 pb-10" style={chatWidth}>
              {chat.map((item, i) => {

                // Document Output
                if(item?.type == 'doc'){
                  return (<StyledMessageRow
                    key={i}
                    className={clsx(
                      'flex flex-col grow-0 shrink-0 items-start justify-start relative ',
                      item.contactId === currentUser.uid ? 'me' : 'contact',
                      { 'first-of-group': isFirstMessageOfGroup(item, i) },
                      { 'last-of-group': isLastMessageOfGroup(item, i) },
                      i + 1 === chat.length && 'pb-0'
                    )}
                  >
                    <ChatDocMessage searchTerm={item.value} />
                    </StyledMessageRow>)
                }

                // None Document Output
                return (
                  <StyledMessageRow
                    key={i}
                    className={clsx(
                      'flex flex-col grow-0 shrink-0 items-start justify-end relative px-16 pb-4',
                      item.contactId === currentUser.uid ? 'me' : 'contact',
                      { 'first-of-group': isFirstMessageOfGroup(item, i) },
                      { 'last-of-group': isLastMessageOfGroup(item, i) },
                      i + 1 === chat.length && 'pb-64'
                    )}
                  >
                    <div className="bubble flex relative items-center justify-center p-12 max-w-full">
                      {(item.contactId != currentUser.uid && item?.type == 'loading')?
                        <FetchingAnswer />
                        :
                        <>
                        <div className="leading-tight whitespace-pre-wrap">{item.value}</div>

                          <div className=" absolute hidden w-full text-11 mt-8 -mb-24 ltr:left-0 rtl:right-0 bottom-0 " style={{display:'flex'}}>
                            <Typography
                              style={{ flexGrow: 1}}
                              className="time leading-tight whitespace-pre-wrap nowrap"
                              color="text.secondary"
                            >                                
                              {
                                item?.createdAt != null &&
                                formatDistanceToNow(new Date(item.createdAt), { addSuffix: true })
                              }
                            </Typography>                            
                            {item.contactId != currentUser.uid && <References chat={item} />}                            
                          </div>
                        </>
                      }
                    </div>
                  </StyledMessageRow>
                );
              })}
            </div>
          )}

          <Recommendations namespace={chatSession?.namespace} chatWidth={chatWidth} chatSession={chatSession} applyHandler={applyRecommendation} />
          
        </div>

      </div>
    </div>
  </>
  )

}


const StyledMessageRow = styled('div')(({ theme }) => ({
  '&.contact': {
    '& .bubble': {
      backgroundColor: '#dceff4', // theme.palette.secondary.light,# dceff4 #4AAEC7  ebebeb
      color: 'black', // theme.palette.secondary.contrastText,
      fontWeight: '480',
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
      minWidth: '300px',

      '& .time': {
        marginLeft: 12,
      },
    },
    '&.first-of-group': {
      '& .bubble': {
        borderTopLeftRadius: 10,
      },
    },
    '&.last-of-group': {
      '& .bubble': {
        borderBottomLeftRadius: 10,
      },
    },
  },
  '&.me': {
    paddingLeft: 40,
    '& .bubbleDoc': {
      marginLeft: 'auto',
      minWidth: '200px',      
      paddingTop: 20,
      paddingBottom: 0,
      marginBottom: 0,      
    },
    '& .bubble': {
      marginLeft: 'auto',
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      minWidth: '200px',
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
      '& .time': {
        justifyContent: 'flex-end',
        right: 0,
        marginRight: 12,
      },
    },
    '&.first-of-group': {
      '& .bubble': {
        borderTopRightRadius: 10,
      },
    },

    '&.last-of-group': {
      '& .bubble': {
        borderBottomRightRadius: 10,
      },
    },
  },
  '&.contact + .me, &.me + .contact': {
    paddingTop: 20,
    marginTop: 20,
  },
  '&.first-of-group': {
    '& .bubble': {
      borderTopLeftRadius: 10,
      paddingTop: 13,
    },
  },
  '&.last-of-group': {
    '& .bubble': {
      borderBottomLeftRadius: 20,
      paddingBottom: 13,
      '& .time': {
        display: 'flex',
      },
    },
  },
}));

function FetchingAnswer() {
  return (<>
    <LoadingDove />
    <div className="leading-tight whitespace-pre-wrap">Your Virtual Assistant is thinking...
    </div>
  </>)
}
export default Messages;