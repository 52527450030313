import ElysianRoutes from "./ElysianRoutes";
import SholomRoutes from "./SholomRoutes";
import FreeRoutes from "./FreeRoutes"


let routes = [...ElysianRoutes.routes, ...SholomRoutes.routes, ...FreeRoutes.routes]

let settings = {
  layout: {
    config: {},
  },
};

const ClientRoutes = {settings, routes}


export default ClientRoutes;