import {  useRef } from 'react';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import References from './References';
import Typography from '@mui/material/Typography';

const styledHtml = {
  '& p': {
    margin: 10,
    padding: '10px'
  }
};

function ChatHtmlMessage({item, user}){

  //const { chat, question, chatSession, response } = props;

  const msgRef = useRef();

  /*
  chat.push({
    "id": chat.length + 1,
    "question": question,
    "namespace": chatSession.namespace,
    "chatId": "",
    "contactId": FLAG_SYS,
    "value": response.answer,
    "response": response,
    "createdAt": new Date()
  })
  <div className="leading-tight whitespace-pre-wrap">{item.html}</div>
  */

 // TODO: Sanitize dangerouslySetInnerHTML
 // https://blog.logrocket.com/using-dangerouslysetinnerhtml-react-application/
 // https://blog.logrocket.com/what-you-need-know-inbuilt-browser-html-sanitization/
 // https://github.com/cure53/DOMPurify

 return (<>
    
    <div className="HtmlMessage" ref={msgRef} dangerouslySetInnerHTML={{ __html: item.html }} />

    <div className="absolute hidden w-full text-11 mt-8 -mb-24 ltr:left-0 rtl:right-0 bottom-0 " style={{display:'flex'}}>
      <Typography
        style={{ flexGrow: 1}}
        className="time leading-tight whitespace-pre-wrap nowrap"
        color="text.secondary"
      >                                
        {
          item?.createdAt != null &&
          formatDistanceToNow(new Date(item.createdAt), { addSuffix: true })
        }
      </Typography>                            
      {item.contactId != user.uid && <References chat={item} />}                            
    </div>
  </>
  )
}

export default ChatHtmlMessage;