import { config } from "dotenv"; 
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { getDatabase } from "firebase/database";
import { getFirestore } from 'firebase/firestore'

config();

/*
console.log(`Environment: ${process.env.REACT_APP_ENV}`);
console.log(`Project: ${process.env.REACT_APP_FIREBASE_PROJECT_ID}`);
console.log(`Measurment: ${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`);
*/

let app = initializeApp({
    apiKey:             process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain:         process.env.REACT_APP_FIREBASE_AUTH_DOMAIN, 
    projectId:          process.env.REACT_APP_FIREBASE_PROJECT_ID, 
    storageBucket:      process.env.REACT_APP_FIREBASE_STORAGE_BUCKET, 
    messagingSenderId:  process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID, 
    appId:              process.env.REACT_APP_FIREBASE_APP_ID, 
    measurementId:      process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
})

export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);
export const db = getDatabase(app);
export const firestore = getFirestore(app);

export function getFirebaseAnalytics() {
    return analytics;
}


export default app;