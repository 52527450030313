import FuseUtils from '@fuse/utils/FuseUtils';
import { Model, Actions} from 'flexlayout-react';
import { TAB_TYPE } from './tabs/TabLayout';
import { v1 as uuid} from 'uuid';
import DocChatV3 from './docChatV3';

class ChatSession extends FuseUtils.EventEmitter {

  constructor(namespace) {    
    super();
    this.id = uuid();
    this.namespace = namespace;
    this.layout = addTabLayout(this);
    this.model = Model.fromJson(this.layout);
    this.layoutRef = null; // used in TabLayout
    this.recommendations = [];
    this.showRecommendations = false; 
    this.filterDirectory = true;   
    this.question = {
      current: null,    // when null, chat directions will display
      previous: null
    }  
    this.tabs = {
      forms: [],
      policies: [],
      regulations: [],
    } 
    this.chat = []
  }

  static Events = {
    onPreparing: 'onPreparing',
    onSubmit: 'onSubmit',
    onOpenDocTab: 'onOpenDocTab',
  };

  // rasie onPreparing
  preparingText(namespace, text){
    this.emit(ChatSession.Events.onPreparing, namespace, text, 'text');
  }

  // raise onSubmit
  submitText(namespace, text){
    this.emit(ChatSession.Events.onSubmit, namespace, text, 'text');
  }

  // raise onOpenDocTab
  openDocTab(docInfo){
    this.emit(ChatSession.Events.onOpenDocTab, docInfo);
  }

  askQuestion(question){
    let newSession = cloneSession(this);
    newSession.question.current = question;
    this.emit('onChange', newSession);
    this.model.doAction(Actions.selectTab(`chat-${this?.namespace}`)); // show chat tab
  }

  createQuestions(title) {
    
    let newSession = cloneSession(this);

    newSession.chat.push({
      "id": uuid(),
      "chatId": uuid(),
      "type": "loading"
    })

    this.emit('onChange', newSession);
    this.model.doAction(Actions.selectTab(`chat-${this?.namespace}`));
    DocChatV3.createQuestionsByDocTitle(this.namespace, title).then(response => {
      newSession.chat.pop();
      this.recommendations = response.data;
      this.showRecommendations = true;
      this.syncCache();
    });  
  }

  syncCache() {
    let newSession = cloneSession(this);
    this.emit('onChange', newSession);
  }
} // ChatSession

function cloneSession(session) {
  let newSession = new ChatSession(session.namespace);
  newSession.layout = session.layout;
  newSession.model = session.model;
  newSession.layoutRef = session.layoutRef;
  newSession.recommendations = session.recommendations;
  newSession.showRecommendations = session.showRecommendations;
  newSession.question.current = session.question.current;
  newSession.question.previous = session.question.previous;
  newSession.tabs.forms = session.tabs.forms;
  newSession.tabs.policies = session.tabs.policies;
  newSession.tabs.regulations = session.tabs.regulations;
  newSession.chat = session.chat;
  return newSession;
}

function addTabLayout(session){
  return {
    global: {},
    borders: [],
    layout: {
      type: "row",
      weight: 100,
      children: [
        {
          id: 'main',
          type: "tabset",
          weight: 100,
          children: [
            {
              id: `dir-${session.namespace}`,
              type: "tab",
              name: TAB_TYPE.DIR,
              component: TAB_TYPE.CONTENT,
              "enableClose": false,
            },
            {
              id: `chat-${session.namespace}`,
              type: "tab",
              name: TAB_TYPE.CHAT,
              component: TAB_TYPE.CONTENT,
              "enableClose": false,
            }
          ]
        }
      ]
    }
  }
}

export default ChatSession;
