import React, {lazy} from 'react';
// const Layout = lazy(() => import('../modules/chat_regulation/layout'));
// const ChatApp = lazy(() => import('../modules/chat_regulation/ChatApp'));

const Layout = lazy(() => import('../modules/ChatApp/ChatAppLayout'));
const ChatApp = lazy(() => import('../modules/ChatApp/ChatApp')); 

const ElysianRoutes = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'Elysian',
      element: <Layout />,
      auth: ['admin', 'elysian', 'demo'],
      children: [
        {
          path: '',
          auth: ['admin', 'elysian', 'demo'],
          element: <ChatApp 
            org={{key: "Elysian", name: "Elysian Senior Homes"}}
            collection="Elysian-1"
            pageTitle="Elysian Resources"
            pageDescription="BrightSource Virtual Assistant"
            chatHeader={<span>Elysian BrightSources</span>}
          />,
        },

        {
          path: 'Competencies',
          auth: ['admin', 'elysian', 'demo'],
          element: <ChatApp 
            org={{key: "Elysian", name: "Elysian Senior Homes"}}
            collection="Elysian-1"
            level1='Competencies'
            pageTitle="Elysian Compentencies"
            pageDescription="BrightSource Virtual Assistant"
            chatHeader={<span>Competencies</span>}
          />,
        },

        {
          path: 'Instructions',
          auth: ['admin', 'elysian', 'demo'],
          element: <ChatApp 
            org={{key: "Elysian", name: "Elysian Senior Homes"}}
            collection="Elysian-1"
            level1='Instructions'
            pageTitle="Elysian Instructions"
            pageDescription="BrightSource Virtual Assistant"
            chatHeader={<span>Instructions</span>}
          />,
        },
        {
          path: 'EmergencyManual',
          auth: ['admin', 'elysian', 'demo'],
          element: <ChatApp 
            org={{key: "Elysian", name: "Elysian Senior Homes"}}
            collection="Elysian-1"
            level1='Emergency Manual'
            pageTitle="Elysian Emergency Manual"
            pageDescription="BrightSource Virtual Assistant"
            chatHeader={<span>Emergency Manual</span>}
          />,
        },
        {
          path: 'EmploymentDocuments',
          auth: ['admin', 'elysian', 'demo'],
          element: <ChatApp 
            org={{key: "Elysian", name: "Elysian Senior Homes"}}
            collection="Elysian-1"
            level1='Employment Documents'
            pageTitle="Elysian Employment Documents"
            pageDescription="BrightSource Virtual Assistant"
            chatHeader={<span>Employment Documents</span>}
          />,
        },

        {
          path: 'PoliciesAndProcedures',
          auth: ['admin', 'elysian', 'demo'],
          element: <ChatApp 
            org={{key: "Elysian", name: "Elysian Senior Homes"}}
            collection="Elysian-1"
            level1='Policies and Procedures'
            pageTitle="Elysian Policies & Procedures"
            pageDescription="BrightSource Virtual Assistant"
            chatHeader={<span>Policies & Procedures</span>}
          />,
        },
        {
          path: 'Regulations',
          auth: ['admin', 'elysian', 'demo'],
          element: <ChatApp 
            org={{key: "Elysian", name: "Elysian Senior Homes"}}
            collection="Elysian-1"
            level1='Regulations'
            pageTitle="Elysian Regulations"
            pageDescription="BrightSource Virtual Assistant"
            chatHeader={<span>Regulations</span>}
          />,
        },
      ],
    },
  ],
};

export default ElysianRoutes;