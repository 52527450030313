import React, {useContext} from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import PromptContext from './context';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

// import FuseSvgIcon from '@fuse/core/FuseSvgIcon';

function Toolbar(props) {
  const { currentPrompt, currentModel, setCurrentModel, setRightSidebarOpen, setShowTestShell } = useContext(PromptContext);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  
  const displayTestSubMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };


  const testDisabled = (currentPrompt?.Enabled == true)? false : true;

  function onNew(){
    alert('This functionality is under development.')
  }

  function handleClose(){
    setAnchorEl(null);    
    
  }

  function onTest(){
    setAnchorEl(null);    
    setShowTestShell(true);
  }
  
  function onConfigure(){

    setRightSidebarOpen(true);
  }
  
  return (
    <div className="flex flex-col sm:flex-row item-center sm:items-start space-y-6 sm:space-y-0 p-6 sm:p-6  w-full border-b-1 flex items-center justify-between">
      <div className="flex flex-col sm:flex-row items-center sm:space-x-4">
       
        <ButtonGroup variant="text" aria-label="text button group" >   
        <Button onClick={onNew} disabled><span style={{marginLeft: '8px', marginRight: '6px'}} >New</span></Button>       
        <Button onClick={onConfigure}><span style={{marginLeft: '6px', marginRight: '6px'}}>Configure</span></Button>
        <Button disabled={testDisabled}
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={displayTestSubMenu}
        >
          <span style={{marginLeft: '6px', marginRight: '6px'}}>Test</span>
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={onTest} disabled>Test with custom data </MenuItem>
          <MenuItem onClick={onTest} >Apply to referral </MenuItem>
        </Menu>      
      </ButtonGroup>
      </div>

      <div className="flex items-center -mx-8">
        
      </div>
    </div>
  );
}

export default Toolbar;