import FusePageSimple from '@fuse/core/FusePageSimple';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import LandingPage from './landing';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
  },
}));

function Layout(props) {

  const routeParams = useParams();
  const [rightSidebarOpen, setRightSidebarOpen] = useState(false);
  const [mainSidebarOpen, setMainSidebarOpen] = useState(false);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const [currentModel, setCurrentModel] = useState();

  useEffect(() => {
      // setRightSidebarOpen(Boolean(routeParams.id));
  }, [routeParams]);

  // rightSidebarContent={<KeyDetailsSidebar />}
  
  let providerValues = ({mainSidebarOpen, setMainSidebarOpen, rightSidebarOpen, setRightSidebarOpen, currentModel, setCurrentModel})

  return (
    <Root
      content={<LandingPage />}
      rightSidebarOpen={rightSidebarOpen}
      rightSidebarOnClose={() => setRightSidebarOpen(false)}
      rightSidebarWidth={400}
      scroll={isMobile ? 'normal' : 'content'}
    />
);

}

export default Layout;