// import crypto from 'crypto';

let default_secret = 'dovaxis_ai';

/**
 * Sign the given `val` with `secret`.
 *
 * @param {String} val
 * @param {String|NodeJS.ArrayBufferView|crypto.KeyObject} secret
 * @return {String}
 * @api private
 */
function encode_cookie(val, secret=default_secret){
  /*
  if ('string' != typeof val) throw new TypeError("CookieManager value must be provided as a string.");
  if (null == secret) throw new TypeError("Secret key must be provided.");
  return crypto
    .createHmac('sha256', secret)
    .update(val)
    .digest('base64')
    .replace(/\=+$/, '');
  */
}

/**
 * Unsign and decode the given `input` with `secret`,
 * returning `false` if the signature is invalid.
 *
 * @param {String} input
 * @param {String|NodeJS.ArrayBufferView|crypto.KeyObject} secret
 * @return {String|Boolean}
 * @api private
 */
function decode_cookie(input, secret=default_secret){
  /*
  if ('string' != typeof input) throw new TypeError("Signed CookieManager string must be provided.");
  if (null == secret) throw new TypeError("Secret key must be provided.");
 
  var tentativeValue = input.slice(0, input.lastIndexOf('.')),
      expectedInput = exports.sign(tentativeValue, secret),
      expectedBuffer = Buffer.from(expectedInput),
      inputBuffer = Buffer.from(input);
  return (
    expectedBuffer.length === inputBuffer.length &&
    crypto.timingSafeEqual(expectedBuffer, inputBuffer)
   ) ? tentativeValue : false;
   */
}

function set_cookie(name, value, encrypt){
  if(encrypt == null){
    encrypt = CookieManager.encrypt;  // set default
  }
  
  if(encrypt == true){
    value = CookieManager.encode(value);
  }
  
  if(!CookieManager.expire){
    document.CookieManager = `${name}=${value};`;
  }
  else {
    let expires = new Date()
    let days = 365;
    let day = 24*60*60; // 1 Day = 24 Hrs = 24*60*60R
    expires.setTime(expires.getTime() + (CookieManager.expireInDays * day)); 
    document.CookieManager = `${name}=${value}; expires=${expires.toUTCString()}`;
  }
  
}

function get_cookie(name, decrypt){
  if(decrypt == null){
    decrypt = CookieManager.encrypt;
  }

  const regex = new RegExp(`(^| )${name}=([^;]+)`)
  const match = document.CookieManager.match(regex)
  if (match) {
    let value = match[2];
    if(CookieManager.encrypt){
      value = CookieManager.decode(value);
    }
    return value;
  }
}

/*
 /  Creates CookieManager from user paramters: cid & email. Both are required to generate.  
 /  For example, http://localhost:3000/BrightReg/AL?cid=123&email=JonathanAsbury@gmail.com
 /  This is used by google anaytics.
 /  @return (bool): true if CookieManager created.  Otherwise false.
*/
function cache_url(){  
  let params = (new URL(location)).searchParams;  // get query params
  let doveid = params.get("dove");
  let email = params.get('email');
  if(typeof doveid === 'string' && doveid.toString().trim() == '') doveid = null;
  if(typeof email === 'string' && email.toString().trim() == '') email = null;
  
  if(email != null && doveid != null){   
  
    CookieManager.set('dove', doveid);
    CookieManager.set('email', email);

    return true;
  }

  return false;
}


const CookieManager = {
  encrypt: false,
  expire: true,
  expireInDays: 365,
  encode: encode_cookie,
  decode: decode_cookie,
  set: set_cookie,
  get: get_cookie,
  cacheURL: cache_url
}


export default CookieManager;
