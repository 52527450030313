import { firestore } from "../Firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { CONTAINER_MAIL } from "./firebase/firestore";



const getMailingList = async (category) => {
  try {
    const docRef = doc(firestore, "MailRecipients", category); // Replace "test" with the actual ID of your document
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      const data = docSnapshot.data();
      return data.Recipients;
    } else {
      console.log("Mailing list not found");
      return null;
    }
  } catch (error) {
    console.error("Error fetching document:", error);
    throw error; // Re-throw or handle the error as appropriate for your application
  }
};

export async function sendNewTicketNotification(user, ticketData) {
  return new Promise(async (resolve, reject) => {
    try {
      let html =
        '<body style="font-family: Poppins, sans-serif; background-color: #f4f4f4; padding: 20px;">' +
        '<table style="max-width: 600px; margin: auto; background: #fff; border: 1px solid #ccc; border-radius: 8px;">' +
        "<tr>" +
        '<td style="background-color: #03787c; color: #fff; padding: 20px; text-align: center; border-top-left-radius: 8px; border-top-right-radius: 8px;">' +
        `<h2>${user.displayName} created a new ticket!</h2>` +
        '<p style="font-size: 16px; margin: 10px 0 0;">The user provided the following information:</p>' +
        "</td>" +
        "</tr>" +
        "<tr>" +
        '<td style="padding: 20px;">' +
        '<h3 style="margin-bottom: 10px;">Ticket Details</h3>' +
        `<p><strong>Issue:</strong> ${ticketData?.issue}</p>` +
        `<p><strong>Path:</strong> ${ticketData?.path}</p>` +
        "</td>" +
        "</tr>" +
        "<tr>" +
        '<td style="padding: 20px;">' +
        '<h3 style="margin-bottom: 10px;">User Information</h3>' +
        `<p><strong>Name:</strong> ${ticketData?.userName}</p>` +
        `<p><strong>Email:</strong> ${ticketData?.userEmail}</p>` +
        `<p><strong>Phone:</strong> ${ticketData?.userPhone}</p>` +
        "</td>" +
        "</tr>" +
        "</table>" +
        "</body>";


      let emailList = await getMailingList("SupportTicket");

      let data = {
        to: emailList,
        triggredByUserId: user.id,
        triggredByUserName: user.displayName,
        message: {
          subject: `New Support Ticket : ${user.displayName} created a new ticket on DOVAXIS!`,
          html: html,
        },
      };
      await setDoc(doc(firestore, CONTAINER_MAIL), data);
      resolve(user);
    } catch (ex) {
      console.log(ex.message);
      reject(ex);
    }
  });
}

export async function sendNewUserNotification(user, dbData){

  return new Promise(async (resolve, reject) => {    

    try {

      let html = '<table role="presentation" width="1000" border="0" cellpadding="0" cellspacing="0" style="border-collapse:collapse;border:0;border-spacing:0;background:#ffffff;">';      
      html += '<tr width="1000"><tb width="1000">';
      html += `${user.displayName} just signed up!`; // 'This is an <code>HTML</code> email body.'
      html += '</tb></tr>';
      html += '<tr><td style="line-height:10px;height:10px;mso-line-height-rule:exactly;">&nbsp;</td></tr>'; // empty line

      html += '<tr width="1000"><tb width="1000">'; // begin user information
      html += `The user provided the following information:`;

      html += '<table role="presentation" width="1000" style="border-collapse:collapse;border:0;border-spacing:0;background:#ffffff;">';      // user table
      
      for(const property in user){
        html += `<tr width="1000" ><tb width="400" >${property}&nbsp;&nbsp;&nbsp;</tb><tb width="600" >${renderType(user[property])}</tb></tr>`;
      }      

      html += '</table>'   
      html += '</tb></tr>'; // end user information        
      html += '</table>';

      const emailList = await getMailingList("NewUser");
      const data = {
        to: emailList,
        triggredByUserId: user.id,
        triggredByUserName: user.displayName,
        message: {
          subject: `Congratulations, ${user.displayName} just registered on DOVAXIS!`,
          html: html
        },
      }

      await setDoc(doc(firestore, CONTAINER_MAIL), data);
      resolve(user);
    }
    catch(ex){
      console.log(ex.message);
      reject(ex);
    }

  })
}

export async function sendNewChatNotification(user, feedbackData){
  const { Feedback, ...restData } = feedbackData;
  const flattenedFeedbackData = {
    ...restData,
    ...Feedback
  }

  try {
    let html = `
    <table role="presentation" width="800" border="0" cellpadding="0" cellspacing="0" style="border-collapse:collapse;border:0;border-spacing:0;background:#ffffff;">
      <tr width="800">
        <td width="800">
          ${user.displayName} just left feedback!
        </td>
      </tr>
      <tr>
        <td style="line-height:10px;height:10px;mso-line-height-rule:exactly;">&nbsp;</td>
      </tr>
      <tr width="800">
        <td width="800">
          Here is the details of the feedback:
        </td>
      </tr>
      <table role="presentation" width="800" style="border-collapse:collapse;border:0;border-spacing:0;background:#ffffff;">
        ${Object.entries(flattenedFeedbackData).map(([property, value]) => `
        <tr width="800">
          <td width="200">${property}&nbsp;&nbsp;&nbsp;</td>
          <td width="600">${renderType(value)}</td>
        </tr>
        `).join('')}
      </table>
    </table>
    `;

    let env = (process.env.REACT_APP_ENV == '')? 'DEV - ' : '';

    const emailList = await getMailingList("BrightSourceFeedback");
    const data = {
      to: emailList,
      triggredByUserId: user.id,
      triggredByUserName: user.displayName,
      message: {
        subject: `${env}${user.displayName} just left feedback on DOVAXIS!`,
        html: html
      },
    }

    await setDoc(doc(firestore, CONTAINER_MAIL), data);
    return user;
  }
  catch(ex){
    console.log(ex.message);
    throw ex;
  }
}

function renderType(value){

  if(value == null) {
    return '';
  }
  if(typeof value === 'string'){
    return value;
  }
  if(typeof value === 'object'){
    return JSON.stringify(value);
  }
}

export async function sendNewBrightenNotification(user, requestData){
  return new Promise(async (resolve, reject) => {    

    try {
      let html = `
      <table role="presentation" width="800" border="0" cellpadding="0" cellspacing="0" style="border-collapse:collapse;border:0;border-spacing:0;background:#ffffff;">
        <tr width="800">
          <td width="800">
            ${user.displayName} just requested for Brighten!
          </td>
        </tr>
        <tr>
          <td style="line-height:10px;height:10px;mso-line-height-rule:exactly;">&nbsp;</td>
        </tr>
        <tr width="800">
          <td width="800">
            Here is the details:
          </td>
        </tr>
        <table role="presentation" width="800" style="border-collapse:collapse;border:0;border-spacing:0;background:#ffffff;">
          ${Object.entries(requestData).map(([property, value]) => `
          <tr width="800">
            <td width="200">${property}&nbsp;&nbsp;&nbsp;</td>
            <td width="600">${value}</td>
          </tr>
          `).join('')}
        </table>
      </table>
      `;

      const emailList = await getMailingList("BrightenRequest");
      const data = {
        to: emailList,
        triggredByUserId: user.id,
        triggredByUserName: user.displayName,
        message: {
          subject: `${user.displayName} just made Brought Source Request DOVAXIS!`,
          html: html
        },
      }

      await setDoc(doc(firestore, CONTAINER_MAIL), data);
      resolve(user);
    }
    catch(ex){
      console.log(ex.message);
      reject(ex);
    }
  })
}
