import { firestore as db } from 'src/app/auth/Firebase';
import { collection, getDocs, getDoc, doc, setDoc, addDoc, query, where, onSnapshot, Timestamp, updateDoc} from 'firebase/firestore';

const CN_REQUESTS = "Referral Requests";

// get Referral Requests
export async function getRequests() {

  return new Promise(async (resolve, reject) => {    
    let results = [];
    
    try {
      
      let col = collection(db, CN_REQUESTS);    
      
      const q = query(col, where("Show", "==", true));

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach( (doc) => {
        let data = doc.data();
        data.id = doc.id;
        results.push(data);
      });
      
    }catch(ex){
        console.log(ex.message);
        reject(ex.message);
    }
    resolve(results);
  });  
}


// get Referral Request
export async function getRequest(requestId) {
  let data;  

  if(requestId == null) return;

  return new Promise(async (resolve, reject) => {
    const docRef = doc(db, CN_REQUESTS, requestId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      data = docSnap.data();
      data.id = docRef.id;      
    } else {      
      console.log("Referral Request not found: " + requestId);
    }
    resolve(data);

  })
  
}

// update Request
// docs: https://cloud.google.com/firestore/docs/manage-data/add-data#update-data
export async function updateRequest(requestId, data) {
  return new Promise(async (resolve, reject) => {
    
    try {
      const docRef = doc(db, CN_REQUESTS, requestId);
      await updateDoc(docRef, data);
      resolve()
    }catch(ex){
      reject(ex);
    }
  })
}