import React, {useState, useContext} from 'react';
import Paper from '@mui/material/Paper';
import Camera from 'react-html5-camera-photo';  // https://www.npmjs.com/package/react-html5-camera-photo
import 'react-html5-camera-photo/build/css/index.css';

export default function CardImporter() {

  function handleTakePhoto (dataUri) {
    // Do stuff with the photo...
    console.log('takePhoto');
  }

  return (
    <Paper className="m-10 p-20 " sx={{ height: '98%', width: '100%' }}>
      
     <Camera 
      idealFacingMode='environment'
      onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }
    />
    </Paper>
  );
}