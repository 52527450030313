import React, {lazy} from 'react';
import Module from './module';

const Routes = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'prompts',
      element: <Module />
    }
  ]
}

export default Routes;